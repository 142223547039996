import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
  } from "@fluentui/react-components/unstable";
  import * as React from "react";
  
  import { 
    Button,
    Text,
    tokens,
  } from "@fluentui/react-components";
  import { 
    Dismiss24Regular,
    Edit24Regular,
    Delete24Regular,
    LocationAdd24Regular,
  } from "@fluentui/react-icons";

  import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
  } from "@fluentui/react-components";
  import type { DropdownProps } from "@fluentui/react-components";
  import { Field, Textarea } from "@fluentui/react-components";
  import type { TextareaProps } from "@fluentui/react-components";
  
  import { useAssetContext, IAssetItem, ILocationItem } from '../../Context/AssetContext'
  
  const useApplyButtonStyles = makeStyles({
    content: {
      display: "flex",
      justifyContent: "left",
      position: "absolute",
      marginBottom: '1em',
      bottom: 0,
      columnGap: tokens.spacingHorizontalXS,
    },
  });

  const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      ...shorthands.padding("50px", "20px"),
      rowGap: "20px",
    },
    panels: {
      ...shorthands.padding(0, "10px"),
      "& th": {
        textAlign: "left",
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
    propsTable: {
      "& td:first-child": {
        fontWeight: tokens.fontWeightSemibold,
      },
      "& td": {
        ...shorthands.padding(0, "30px", 0, 0),
      },
    },
  });

// Open Asset Drawer
export const OpenAsset = (props: IAssetItem) => {
  const {locations, patchAsset} = useAssetContext();
  const refNotes = React.useRef<string>(props.notes || '')
  //const refLocationId = React.useRef<string>(props.locationId || '')
  //const refDeviceStatus = React.useRef<string>(props.deviceStatus || '')
  const [isOpen, setIsOpen] = React.useState(false);
  const [locationId,setLocationId] = React.useState<string>(props.locationId || '')
  const [notes,setNotes] = React.useState<string>(props.notes || '')
  const [deviceStatus, setDeviceStatus] = React.useState<string>(props.deviceStatus || '')
  const styles = useStyles();
  const applyStyles = useApplyButtonStyles();
  
  const defaultLocationName = (Array.isArray(locations) && locations.length > 0) ? (
    locations.find((element: ILocationItem) => {
      return element.id === props.locationId;
    })?.locationName ?? ""
  ) : ("")
    
  // Apply
  const onClickApplyButton = React.useCallback(() => {
    setIsOpen(false);
    if ((notes !== props.notes) || 
        (deviceStatus !== props.deviceStatus) || 
        (locationId !== props.locationId)) {
      console.log({
        ...props,
        notes: notes,
        locationId: locationId,
        deviceStatus: deviceStatus,
      })
      patchAsset({
        ...props,
        notes: notes,
        locationId: locationId,
        deviceStatus: deviceStatus,
      })
    }
  }, [props, notes, locationId, deviceStatus, patchAsset]);

  // Notes
  const textLimit = 100
  const onChange: TextareaProps["onChange"] = (ev, data) => {
    if (data.value.length <= textLimit) {
      setNotes(data.value);
    }
  }

  // Device Status
  const onOptionSelectDeviceStatus: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setDeviceStatus(data.selectedOptions[0])
    //setSelectedOptions(data.selectedOptions);
    //setValue(data.optionText ?? "");
    //refDeviceStatus.current = data.selectedOptions[0];
  };

  const DeviceStatusDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={deviceStatus}
        placeholder="Select a location"
        {...props}
        defaultValue={deviceStatus}
        defaultSelectedOptions={!deviceStatus ? ( [] ) : ( [deviceStatus])}
        onOptionSelect={onOptionSelectDeviceStatus}
      >
        {['Inactive','Available','Decommissioned'].map((status:string) => (
          <Option text={status} value={status}>
            {status}
          </Option>
        ))}
      </Dropdown>
    )
  },[deviceStatus])

  // Location
  const onOptionSelectLocation: (DropdownProps)["onOptionSelect"] = (ev, data) => {
    setLocationId(data.optionValue ?? "")
  };

  const LocationDropdown = React.useCallback((props: Partial<DropdownProps>) => {
    return (
      <Dropdown
        aria-labelledby={"Location"}
        placeholder="Select a location"
        {...props}
      >
        {locations.map((location:ILocationItem) => (
          <Option key={location.locationName} value={location.id}>
            {location.locationName}
          </Option>
        ))}
      </Dropdown>
    );
  },[locations]);

  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Device details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <p>
          <Text font="monospace">
            <div role="tabpanel" aria-labelledby="item">
              <table className={styles.propsTable}>
                <tbody>
                  {
                    Object.entries(props).map(([key, value]) => 
                      ( value === true || value === false ) ? (
                          <tr><td>Boolean {key}</td><td>{value.toString()}</td></tr>
                        ):(
                          (key === 'locationId') ? (
                            <tr><td>Location</td><td>
                              <LocationDropdown 
                                defaultValue={defaultLocationName}
                                defaultSelectedOptions={[props.locationId]}
                                onOptionSelect={onOptionSelectLocation}
                              /><br />
                            </td></tr>
                          ) : (key === 'notes') ? (
                            <tr>
                              <td>Notes</td>
                              <td>
                                <Field label={"Notes is limited to " + textLimit + " characters"}>
                                  <Textarea resize="vertical" value={notes} onChange={onChange} />
                                </Field>
                              </td>
                            </tr>
                          ) : (key === 'deviceStatus' && (props.deviceStatus === 'Inactive' || props.deviceStatus === 'Available' || props.deviceStatus === 'Assigned')) ? (
                            <tr><td>Device status</td><DeviceStatusDropdown /></tr>
                          ) : (
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        )
                      )
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>
      <Button appearance="outline" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};

// Open Location Drawer
export const OpenLocation = (props: ILocationItem) => {
  const {patchLocation, deleteLocation} = useAssetContext();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  //const locationName:string = (props.locationName || '');
  //const [newLocationName, setNewLocationName] = React.useState<string>();
  const refLocationName = React.useRef<string>(props.locationName || '');
  const applyStyles = useApplyButtonStyles();
  const styles = useStyles();

  const onClickApplyButton = React.useCallback(() => {
    setIsOpen(false);
    if (refLocationName.current !== props.locationName) {
      // update location object by calling PatchLocations function.
      //alert('newLocationName = ' + refLocationName.current)
      patchLocation({
        ...props,
        locationName: refLocationName.current
      })
    }
  }, [props, patchLocation]);

  const onClickDelete = React.useCallback(() => {
    if(window.confirm('Delete location? (' + props.locationName + ')' )) {
      // Delete item
      deleteLocation({
        id: props.id
      })      
    }
    // Close Drawer
    setIsOpen(false);
  }, [props, deleteLocation]);

  const LocationNameTextArea = () => {
    const [value, setValue] = React.useState<string>(props.locationName || '');
    const textLimit = 50
    const onChange: TextareaProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setValue(data.value);
        refLocationName.current = data.value;
      }
    };

    return (
      <>
      <Field label={"Location name is limited to " + textLimit + " characters"}>
        <Textarea value={value} onChange={onChange} />
      </Field>
      </>
    );
  };
  
  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <>
                <Button
                  appearance="subtle"
                  aria-label="Delete"
                  icon={<Delete24Regular />}
                  onClick={onClickDelete}
                />
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              </>
            }
          >
            Location Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
            <div role="tabpanel" aria-labelledby="item">
              <table className={styles.propsTable}>
                <tbody>
                  {
                    Object.entries(props).map(([key, value]) => (
                      (key === 'locationName') ? (
                        <tr><td>Location Name</td><td><LocationNameTextArea /></td></tr>
                      ) : ( 
                        <tr><td>{key}</td><td>{value}</td></tr>
                      )
                    ))
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            <Button shape="square" appearance="outline" onClick={onClickDelete}><Delete24Regular />Delete</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>

      <Button appearance="outline" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );

};

  // Open Location Drawer
export const AddLocation = () => {
  const {addLocation} = useAssetContext();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  //const [newLocationName, setNewLocationName] = React.useState<string>();
  const refLocationName = React.useRef<string>('');
  
  const onClickAddButton = React.useCallback(() => {
    setIsOpen(false);
    addLocation({
      locationName: refLocationName.current
    })
  }, [addLocation]);

  const applyStyles = useApplyButtonStyles();

  const LocationNameTextArea = () => {
    const [value, setValue] = React.useState<string>('');
    const textLimit = 50
    const onChange: TextareaProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setValue(data.value);
        refLocationName.current = data.value;
      }
    };

    return (
      <>
      <Field label={"Location name is limited to " + textLimit + " characters"}>
        <Textarea value={value} onChange={onChange} />
      </Field>
      </>
    );
  };
  
  const useDrawerStyles = makeStyles({
    content: {
      display: "flex",
      justifyContent: "left",
      alignItems: "flex-start",
      columnGap: tokens.spacingHorizontalXS,
    },
  });

  const styles = useStyles();
  const drawerStyles = useDrawerStyles();
  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <>
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              </>
            }
          >
            Add Location
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              {
                // drawerContent
              }

              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                  {
                    <tr><td>Location Name</td><td><LocationNameTextArea /></td></tr>
                  }
                </tbody>  
              </table>
            </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickAddButton}>Add</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>
      <div className={drawerStyles.content}>
        <Button 
          shape="square"
          appearance="subtle"
          aria-label="New location"
          onClick={() => setIsOpen(true)} 
          icon={<LocationAdd24Regular />}
          size="medium"
        >New location</Button>
      </div>
    </div>
  );
};
