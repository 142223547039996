import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer } from "./ClientprincipalContext";

export interface ISubscriptionItem {
  id: string | undefined;
  isEnabled: boolean | undefined;
}

export interface ISettingsObject {
  zerotouchCompanyId: string | undefined;
  zerotouchCompanyName: string | undefined;
  notificationEmail: string | undefined;
}

export interface ILogItem {
    "Timestamp": string;
    "assignedMetadata": string;
    "deviceIdentifier": string;
    "logDateTime": string;
    "newConfiguration": string;
    "previousConfiguration": string;
    "status": string;
}

export interface ISettingsValidationStateObject{
  tenantId: string;
  zeroTouchCompanyId: string;
  zeroTouchCompanyName: string;
  customerAccess: boolean;
  resellerInvite: boolean;
}

export type AztaContextType = {
  serviceOffer: IServiceOffer | undefined;
  settings: ISettingsObject | undefined,
  items: ILogItem[];
  isLoading: boolean;
  subscription: ISubscriptionItem | undefined;
  validationState: ISettingsValidationStateObject | undefined;
  refreshItems: () => void;
  refreshSubscription: () => void;
  mergeSubscription: (props: Partial<ISubscriptionItem>) => void;
  getSettings: () => void;
  mergeSettings: (props: Partial<ISettingsObject>) => void;
  validateSettings: (props: Partial<ISettingsObject>) => void;
};

export interface IProviderProps {
  children?: any;
}

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

export const DefaultISettingsObject = {
  zeroTouchCompanyId: undefined,
  zeroTouchCompanyName: undefined,
  notificationEmail: undefined,
};

const AztaDefaultValue = {
  serviceOffer: undefined,
  settings: undefined,
  subscription: undefined,
  items: [],
  isLoading: false,
  validationState: undefined,
  refreshItems: () => {},
  refreshSubscription: () => {},
  mergeSubscription: (props: Partial<ISubscriptionItem>) => {},
  getSettings: () => {},
  mergeSettings: (props: Partial<ISettingsObject>) => {},
  validateSettings: (props: Partial<ISettingsObject>) => {},
};

const AztaContext = React.createContext<AztaContextType>(AztaDefaultValue)

export const useAztaContext = () => {
  const context = React.useContext(AztaContext);
  if (!context) {
    throw new Error(
      `useAztaContext must be used within a AztaProvider`
    )
  }
  return context;
}

export const AztaProvider = (props: IProviderProps) => {
  const [items, setItems] = React.useState<ILogItem[]>([]);
  const [subscription, setSubscription] = React.useState<ISubscriptionItem | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false)
  const [validationState, setValidationState] = React.useState<ISettingsValidationStateObject>();
  const {clientprincipal, serviceOffers} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
  const [settings, setSettings] = React.useState<ISettingsObject | undefined>(undefined)

  const refreshSubscription = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && typeof clientprincipal?.tenantId === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/azta/subscription', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISubscriptionItemValue) : (undefined))))
      .then((result) => {setSubscription(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);
  
  const mergeSubscription = React.useCallback((props: Partial<ISubscriptionItem>) => {
    console.log('patchSubscription')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(subscription)
      console.log('isEnabled: ' + props.isEnabled)
      // Update Subscription
      console.log('Updating subscription')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          isEnabled: props.isEnabled,
        }),
      };
      fetch('/api/azta/subscription', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshSubscription()
      ) : (
        console.log('Failed to set subscription.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshSubscription()
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, subscription, refreshSubscription])

  const getSettings = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && typeof clientprincipal?.tenantId === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/azta/settings', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISettingsObject) : (undefined))))
      .then((result) => {setSettings(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);

  const mergeSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    console.log('mergeSettings')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(settings)
      console.log(props)
      console.log('Updating aztaSettings')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          zerotouchCompanyId: props.zerotouchCompanyId,
          zerotouchCompanyName:props.zerotouchCompanyName,
          notificationEmail:props.notificationEmail
        }),
      };
      fetch('/api/azta/settings', requestOptions)
      .then((response) => (response.status === 204) ? (
        getSettings()
      ) : (
        console.log('Failed to set aztaSettings.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, settings, getSettings])

  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && clientprincipal.tenantId  && subscription?.isEnabled){
      
      // Get Items
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/azta/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => setItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
      
    } else {
      setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, subscription?.isEnabled])

  const validateSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('azta') && clientprincipal.tenantId ){
      
      // Validate Subscription
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify({
          tenantId: clientprincipal.tenantId,
          zeroTouchCompanyId: props.zerotouchCompanyId,
          zeroTouchCompanyName: props.zerotouchCompanyName,
        }),
      };
      fetch('/api/azta/subscription/validate', requestOptions)
      .then((response) => response.json())
      .then((result) => {setValidationState(result);console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        //setIsLoading(false);
      })

    } else {
      alert("isAuthenticated: " + clientprincipal?.isAuthenticated + " :: Azta roles: " + clientprincipal?.roles?.includes('azta') + " :: tenantId: " + clientprincipal.tenantId)
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId])

  React.useEffect(() => {
    refreshItems();
  }, [clientprincipal,subscription, refreshItems]);

  React.useEffect(() => {
    refreshSubscription();
  }, [clientprincipal, refreshSubscription]);

  React.useEffect(() => {
    (settings?.zerotouchCompanyId && settings?.zerotouchCompanyName) && (
      validateSettings(
        {
          zerotouchCompanyId: settings?.zerotouchCompanyId,
          zerotouchCompanyName: settings?.zerotouchCompanyName,
        }
      )
    )
  }, [settings, clientprincipal, validateSettings]);

  React.useEffect(() => {
    getSettings();
  }, [clientprincipal, getSettings]);

  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "azta";
      }))
  },[serviceOffers, setServiceOffer]);

  const value = React.useMemo(() => ({settings, serviceOffer, items, isLoading, subscription, validationState, refreshItems, refreshSubscription, mergeSubscription, getSettings, mergeSettings, validateSettings}), [settings, serviceOffer, items, isLoading, subscription, validationState, refreshItems, refreshSubscription, mergeSubscription, getSettings, mergeSettings, validateSettings])
  return <AztaContext.Provider value={value} {...props} />
}
