import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer } from "./ClientprincipalContext";

export interface ISubscriptionItem {
  tenantid: string | undefined;
  isEnabled: boolean | undefined;
}

export interface ISettingsObject {
  notificationEmail: string | undefined;
}

export const DefaultISettingsObject = {
  notificationEmail: undefined,
};

// Log  item
export interface ILogItem {
  groupDisplayName: string;
  groupId: string;
  groupMemberCount: number;
  logDateTime: string;
  nextTermQuantity: number;
  offerName: string;
  offset: number;
  orderQuantity: number;
  previousQuantity: number;
  quantity: number;
  refundableTotalQuantity: number;
  subscriptionId: string;
  status: string;
}

// Subscription item
export interface ICspSubscriptionItem {
  id: string; // subscriptionId
  quantity: number;
  offerName: string;
  groupid: string;
  offset: number;
  approvedby: string;
  approvalDateTime: string;
  groupDisplayname: string;
  groupMemberCount: number;
}

// Group item assigned to a subscription
export interface IGroupItem {
  id: string; // groupId
  displayName: string;
  groupMemberCount: number;
}

export type MSCSPContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: ISubscriptionItem | undefined;
  subscriptions: ICspSubscriptionItem[];
  logs: ILogItem[];
  groups: IGroupItem[];
  isLoading: boolean;
  isLoadingLogs: boolean;
  settings: ISettingsObject | undefined;
  refreshSubscription: () => void;
  patchSubscription: (props: Partial<ISubscriptionItem>) => void;
  patchManagedSubscription: (props: ICspSubscriptionItem) => void;
  deleteManagedSubscription: (props: ICspSubscriptionItem) => void;
  refreshSubscriptions: () => void;
  refreshLogs: () => void;
  mergeSettings: (props: Partial<ISettingsObject>) => void;
};

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

const MSCSPDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  subscriptions: [],
  logs: [],
  groups: [],
  isLoading: false,
  isLoadingLogs: false,
  settings: undefined,
  refreshSubscription: () => {},
  patchSubscription: (props: Partial<ISubscriptionItem>) => {},
  patchManagedSubscription: (props: ICspSubscriptionItem) => {},
  deleteManagedSubscription: (props: ICspSubscriptionItem) => {},
  refreshSubscriptions: () => {},
  refreshLogs: () => {},
  mergeSettings: (props: Partial<ISettingsObject>) => {},
};

export interface IProviderProps {
children?: any;
}

const MSCSPContext = React.createContext<MSCSPContextType>(MSCSPDefaultValue)

export const useMSCSPContext = () => {
  const context = React.useContext(MSCSPContext);
  if (!context) {
    throw new Error(
      `useAssetContext must be used within a AssetProvider`
    )
  }
  return context;
}

export const MSCSPProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<ISubscriptionItem | undefined>(undefined);
  const [subscriptions, setSubscriptions] = React.useState<ICspSubscriptionItem[]>([]);
  const [logs, setLogs] = React.useState<ILogItem[]>([]);
  const [groups, setGroups] = React.useState<IGroupItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingLogs, setIsLoadingLogs] = React.useState<boolean>(false)
  const {clientprincipal, serviceOffers} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
  const [settings, setSettings] = React.useState<ISettingsObject | undefined>(undefined)

  const refreshSubscription = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/mscsp/subscription', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISubscriptionItemValue) : (undefined))))
      .then((result) => {setSubscription(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    } else {
      // setSubscription(DefaultISubscriptionItemValue)
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);
  
  const patchSubscription = React.useCallback((props: Partial<ISubscriptionItem>) => {
    console.log('patchSubscription')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(subscription)
      console.log('isEnabled: ' + props.isEnabled)
      // Update Subscription
      console.log('Updating subscription')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          isEnabled: props.isEnabled,
        }),
      };
      fetch('/api/mscsp/subscription', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshSubscription()
      ) : (
        console.log('Failed to set subscription.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshSubscription()
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, subscription, refreshSubscription])

  const refreshLogs = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string'){
      // Get Logs
      setIsLoadingLogs(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/mscsp/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => setLogs(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingLogs(false);
      })
      
    } else {
      setLogs([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);

  const refreshSubscriptions = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string'){
      // Get Subscriptions
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubscriptions(result.subscriptions)
        setGroups(result.groups)
      })
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
      
    } else {
      setSubscriptions([])
      setGroups([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);

  const patchManagedSubscription =  React.useCallback((props: ICspSubscriptionItem) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string' && typeof clientprincipal?.userName === 'string'){
      
      // Patch location
      console.log(JSON.stringify(props))
      const requestOptions = {
        method: 'PUT',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userPrincipalname': clientprincipal.userName,
                  },
        body: JSON.stringify(
          {
            id: props.id,
            offerName: props.offerName,
            groupId: props.groupid,
            offset: props.offset
          }),
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshSubscriptions();
      })

    } else {
      alert('An error occured when updating object.')
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, refreshSubscriptions])

  const deleteManagedSubscription = React.useCallback((props: Partial<ICspSubscriptionItem>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string'){
      
      // Patch subscription management object
      console.log('deleteManagedSubscription: ' + props.id)
      const requestOptions = {
        method: 'DELETE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify({id: props.id}),
      };
      fetch('/api/mscsp/subscriptions', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshSubscriptions();
      })

    } else {
      alert('An error occured when deleting object.')
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, refreshSubscriptions])
  
  const getSettings = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && typeof clientprincipal?.tenantId === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/mscsp/settings', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISettingsObject) : (undefined))))
      .then((result) => {setSettings(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);

  const mergeSettings = React.useCallback((props: Partial<ISettingsObject>) => {
    console.log('mergeSettings')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('mscsp') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(settings)
      console.log(props)
      console.log('Updating settings')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          notificationEmail:props.notificationEmail
        }),
      };
      fetch('/api/mscsp/settings', requestOptions)
      .then((response) => (response.status === 204) ? (
        getSettings()
      ) : (
        console.log('Failed to set settings.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, settings, getSettings])

  // Init subscriptions and group array
  React.useEffect(() => {
    refreshSubscriptions();
  },[clientprincipal, refreshSubscriptions]);

  React.useEffect(() => {
    refreshLogs();
  },[clientprincipal, refreshLogs]);

 // Init subscription object
 React.useEffect(() => {
    refreshSubscription();
  },[clientprincipal, refreshSubscription]);

  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "mscsp";
      }))
  },[serviceOffers, setServiceOffer]);

  React.useEffect(() => {
    getSettings();
  }, [clientprincipal, getSettings]);

  const value = React.useMemo(() => ({serviceOffer, subscription, subscriptions, logs, groups, isLoading, isLoadingLogs, settings, refreshSubscription, patchSubscription, patchManagedSubscription, deleteManagedSubscription, refreshSubscriptions, refreshLogs, mergeSettings}), [serviceOffer, subscription, subscriptions, logs, groups, isLoading, isLoadingLogs, settings, refreshSubscription, patchSubscription, patchManagedSubscription, deleteManagedSubscription, refreshSubscriptions, refreshLogs, mergeSettings])
  return <MSCSPContext.Provider value={value} {...props} />
}
