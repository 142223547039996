import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';

// Service Management
import {default as ServicesStart} from './Services/Start';
import {default as ServicesManagement} from './Services/Management';

// Billing Management
import {default as BillingStart} from './Billing/Start';
import {default as BillingManagement} from './Billing/Management';

// RADIUS Service
import {default as RadiusStart} from './Radius/Start';
import {default as RadiusManagement} from './Radius/Management';

// AZTA Service
import {default as AZTAStart} from './AZTA/Start';
import {default as AZTAManagement} from './AZTA/Management';

// Applications Management Service
import {default as ApplicationsStart} from './Applications/Start';
import {default as ApplicationsManagement} from './Applications/Management';

// MSCSP Management Service
import {default as MSCSPStart} from './MSCSP/Start';
import {default as MSCSPManagement} from './MSCSP/Management';


// Asset Management
import {default as AssetStart} from './Asset/Start';
import {default as AssetManagement} from './Asset/Management';

// Profile
import {default as Profile} from './Profile/Profile';

// Import Context
import { useClientprincipal } from '../Context/ClientprincipalContext'


function MainPane() {
  const {clientprincipal} = useClientprincipal()

  if (!clientprincipal.isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element ={<Home />}/>
        <Route path="/login" link="/.auth/login/aad" />
        <Route path="/logout" link="/.auth/logout" />
        <Route path="/me" link="/.auth/me" />
        <Route path="/profile" element ={<Profile />}/>
        <Route path="/home" element ={<Home />}/>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element ={<Home />}/>
      <Route path="/login" link="/.auth/login/aad" />
      <Route path="/logout" link="/.auth/logout" />
      <Route path="/me" link="/.auth/me" />
      <Route path="/home" element ={<Home />}/>
      <Route path="/profile" element ={<Profile />}/>
      <Route path="/services" element ={<ServicesStart />}/>
      <Route path="/services/management" element ={<ServicesManagement />}/>
      <Route path="/radius" element ={<RadiusStart />}/>
      <Route path="/radius/management" element ={<RadiusManagement />}/>
      <Route path="/azta" element ={<AZTAStart />}/>
      <Route path="/azta/management" element ={<AZTAManagement />}/>
      <Route path="/applications" element ={<ApplicationsStart />}/>
      <Route path="/applications/management" element ={<ApplicationsManagement />}/>
      <Route path="/mscsp" element ={<MSCSPStart />}/>
      <Route path="/mscsp/management" element ={<MSCSPManagement />}/>
      <Route path="/asset" element ={<AssetStart />}/>
      <Route path="/asset/Management" element ={<AssetManagement />}/>
      <Route path="/billing" element ={<BillingStart />}/>
      <Route path="/billing/Management" element ={<BillingManagement />}/>
    </Routes>
  );
}

export default MainPane;