import * as React from "react";

import {
  makeStyles,
  Text,
  Body1,
  Caption1,
  Button,
  shorthands,
  tokens,
} from "@fluentui/react-components";
//import { ArrowReplyRegular, ShareRegular } from "@fluentui/react-icons";
import {
  Card,
  CardFooter,
  CardHeader,
  //CardPreview,
} from "@fluentui/react-components";
import { 
    Save24Regular,
    Dismiss24Regular,
    Edit24Regular,
    //CheckmarkCircleRegular,
    //WarningRegular,
    //HeartPulseRegular,
    HeartPulseCheckmarkRegular,
    HeartPulseWarningRegular,
} from "@fluentui/react-icons";
import {
    Switch,
    SwitchProps,
    //Spinner,
} from "@fluentui/react-components";
import type { 
  //TextareaProps, 
  InputProps 
} from "@fluentui/react-components";
import { 
  Field, 
  Input, 
  //Textarea
} from "@fluentui/react-components";
import { useAztaContext as useContext } from "../../Context/AztaContext";
import type { ISubscriptionItem } from "../../Context/AztaContext";


const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const ServiceCard = () => {
  const {serviceOffer, subscription, settings, validationState, mergeSubscription, mergeSettings} = useContext();
  const styles = useStyles();
  const [subscriptionSettings, setSubscriptionSettings] = React.useState<ISubscriptionItem>(subscription || {id: undefined, isEnabled: false});
  const [isEdit , setIsEdit] = React.useState<boolean>(false);
  // const [isValidated , setIsValidated] = React.useState<boolean>(false);
  const email_regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/; 
  const [idValue, setIdValue] = React.useState<string>(settings?.zerotouchCompanyId || '');
  const [idValid, setIdValid] = React.useState<boolean>(((settings?.zerotouchCompanyId || '').match("^[0-9]{10}$")) ? (true) : (false))
  const [nameValue, setNameValue] = React.useState<string>(settings?.zerotouchCompanyName || '');
  const [nameValid, setNameValid] = React.useState<boolean>(((settings?.zerotouchCompanyName || '').match("^.{2,50}$"))? (true) : (false));
  const [emailValue, setEmailValue] = React.useState<string>(settings?.notificationEmail || '');
  const [emailValid, setEmailValid] = React.useState<boolean>(((emailValue.length === 0) || (emailValue || '').match(email_regex))? (true) : (false));

  // Subscription Management
  const onClickSaveButton = React.useCallback(() => {
    mergeSubscription(
      {
        isEnabled: subscriptionSettings.isEnabled
      }
    )
  }, [subscriptionSettings, mergeSubscription]);

  const SaveButton = React.useCallback(() => {
    return (
        ((subscription && (subscriptionSettings.isEnabled !== subscription?.isEnabled) && (validationState?.customerAccess && validationState?.resellerInvite)) || (!subscriptionSettings.isEnabled && subscription?.isEnabled)) ? (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton}>Save</Button>
        ) : (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" disabled>Save</Button>
        )

    )
  },[subscriptionSettings, subscription, onClickSaveButton, validationState])

  const SubscriptionEnabled = React.useCallback((props: SwitchProps) => (
    <Switch 
      //label={subscriptionSettings.isEnabled ? ("Enabled") : ("Disabled")}
      checked={subscriptionSettings.isEnabled}
      onChange={() => setSubscriptionSettings({
        ...subscriptionSettings,
        isEnabled: !(subscriptionSettings.isEnabled)
      })}
      {...props}
    />
  ),[subscriptionSettings]);

  // Settings Management
  const onClickSaveAztaSettings = React.useCallback(() => {
    mergeSettings(
      {
        zerotouchCompanyId: idValue,
        zerotouchCompanyName: nameValue,
        notificationEmail: emailValue,
      })
  }, [idValue, nameValue, emailValue, mergeSettings]);

  const AztaSettingsButton = React.useCallback(() => {
    return (
      (isEdit) ? (
        <>
        <Button icon={<Dismiss24Regular />} shape="square" appearance="subtle" onClick={onClickSaveAztaSettings}>Cancel</Button>
        {
          ((nameValid && idValid) && ((settings?.zerotouchCompanyName !== nameValue) || (settings?.zerotouchCompanyId !== idValue))) ? (
              <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveAztaSettings}>Save</Button>
          ) : (
            <Button icon={<Save24Regular />} shape="square" appearance="subtle" disabled >Save</Button>
          )
        }
        </>
      ) : (
        <Button icon={<Edit24Regular />} shape="square" appearance="subtle" onClick={() => setIsEdit(true) }>Edit</Button>
      )
    )
  },[isEdit, onClickSaveAztaSettings, nameValid, idValid, nameValue, idValue, settings])

  const onIdChange: InputProps["onChange"] = (ev, data) => {
    if (data.value.length <= 10) {
      setIdValue(data.value);
    }

    // Validation rule
    if (data.value.match("^[0-9]{10}$")) { // (refZerotouchCompanyId.current.match('^\d{0,10}$')
      setIdValid(true);
    } else {
      setIdValid(false);
    }
  }

  const onNameChange: InputProps["onChange"] = (ev, data) => {
    if (data.value.length <= 50) {
      setNameValue(data.value);
    }

    // Validation rule
    if (data.value.match("^.{2,50}$")) {
      setNameValid(true);
    } else {
      setNameValid(false);
    }
  };

  const onEmailChange: InputProps["onChange"] = (ev, data) => {
    if (data.value.length <= 50) {
      setEmailValue(data.value);
    }

    // Validation rule
    if (data.value.length === 0 || data.value.match(email_regex)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }

  return (
    <>
      <Card className={styles.card}>
        <CardHeader
          image = {
              <SubscriptionEnabled />
          }
          header={
              <Body1>
                  <b>{serviceOffer?.displayname}</b>
              </Body1>
          }
          description={
              <Caption1>
                  {`${serviceOffer?.retailprice} ${serviceOffer?.currencycode} per ${serviceOffer?.unittype}`}
              </Caption1>
          }
        />
          <div className={styles.description}>
            {serviceOffer?.description}
          </div>
        <CardFooter>
          <SaveButton />
        </CardFooter>
      </Card>
      <Card className={styles.card}>
        <CardHeader
          image={
            <Text size={800}>
              {
                (validationState?.customerAccess && validationState?.resellerInvite) ? 
                ( <HeartPulseCheckmarkRegular color="green" /> ) : ( <HeartPulseWarningRegular color="yellow" /> )
              }
            </Text>
          }
          header={
              <Body1>
                    <b>
                    Settings
                  </b>
              </Body1>
          }
          description={
              <Caption1>
                  {serviceOffer?.displayname}
              </Caption1>
          }
        />
        <table className={styles.propsTable}>
          <tbody>
            <tr>
              <Field
                label="Company Id in Zero-touch portal (10 digits - example: 1234567890)"
                validationState={idValid ? "success" : "warning"}
                validationMessage={idValid ? "" : "Required: Input must be a 10 digit company id."}
              >
                <Input value={idValue} onChange={onIdChange} disabled={!isEdit} />
              </Field>
            </tr>
            <tr>
            <Field 
              label="Company Name in Zero-touch portal (example: Contoso)"
              validationState={nameValid ? "success" : "warning"}
              validationMessage={nameValid ? "" : "Required: Input is limited 50 characters."}
              >
              <Input value={nameValue} onChange={onNameChange} disabled={!isEdit} />
            </Field>
            </tr>
            <tr>
            <Field 
              label="Service notification e-mail address"
              validationState={emailValid ? "success" : "warning"}
              validationMessage={emailValid ? (emailValue.length === 0 ? "Optional" : "") : "Optional: Limited to 50 characters."}
              >
              <Input value={emailValue} onChange={onEmailChange} disabled={!isEdit} />
            </Field>
            </tr>
          </tbody>
        </table>
        <CardFooter>
          <AztaSettingsButton />
        </CardFooter>
      </Card>
    </>
  );
};