import * as React from "react";
import {
  //Button,
  makeStyles,
  shorthands,
  Body1,
  LargeTitle,
  Subtitle1,
  Title3,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

export const Faq = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
        <LargeTitle>Service Management Instructions</LargeTitle>
        <Body1 align="justify">Ths guide describes how to enable and disable services.</Body1>
        <Title3>1. Approve the Modern Managed Intune Application Manaagement Service application.</Title3>
        <Body1 align="justify">Allow the Modern Managed Application Management Service application to access your AzureAD tenant.</Body1>
        <Title3>2. Subscribe to Applications</Title3>
        <Subtitle1>3.1 Subscribe to Applications</Subtitle1>
        <Body1 align="justify">
          <li>Subscribe to a channel (General, Update, Preview)</li>
        </Body1>
        <Subtitle1>3.2 Subscribe to Applications</Subtitle1>
        <Body1 align="justify">
          <li>Assign the subscribed application to devices or users.</li>
        </Body1>
      </div>
    )
};

export default Faq;