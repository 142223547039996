import * as React from "react";
import {
  //Accordion,
  //AccordionHeader,
  //AccordionItem,
  //AccordionPanel,
  CompoundButton,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { 
  CertificateRegular,
  AppsRegular,
  PhoneLaptopRegular,
  SettingsRegular,
  PhoneLinkSetupRegular,
  WifiLockRegular,
  MoneyCalculatorRegular,
 } from "@fluentui/react-icons";
 import { useNavigate } from 'react-router-dom';
import { useClientprincipal } from '../Context/ClientprincipalContext'
/*
import { Default as ApplicationsMenu } from './Applications/Menu';
import { Default as ServicesMenu } from './Services/Menu';
import { Default as  CostManagment} from './Cost/Menu';
import { Default as RadiusMenu } from './Radius/Menu';
import { Default as MSCSPMenu } from './MSCSP/Menu';
import { Default as AZTAMenu } from './AZTA/Menu';
import { Default as AssetMenu } from './Asset/Menu';
*/

const services = [
  {
    id: 'serviceadministrator',
    name: 'Billing',
    //menu: <CostManagment />,
    icon: <MoneyCalculatorRegular />,
    uri: 'billing/management',
  },
  {
    id: 'serviceadministrator',
    name: 'Service Management',
    //menu: <ServicesMenu />,
    icon: <SettingsRegular />,
    uri: 'services/management',
  },
  {
    id: 'application',
    name: 'Application Management',
    //menu: <ApplicationsMenu />,
    icon: <AppsRegular />,
    uri: 'applications/management',
  },
  {
    id: 'asset',
    name: 'Asset Management',
    //menu: <AssetMenu />,
    icon: <PhoneLaptopRegular />,
    uri: 'asset/management',
  },
  {
    id: 'mscsp',
    name: 'MS CSP Management',
    //menu: <MSCSPMenu />,
    icon: <CertificateRegular />,
    uri: 'mscsp/management',
  },
  {
    id: 'radius',
    name: 'Radius Management',
    //menu: <RadiusMenu />,
    icon: <WifiLockRegular />,
    uri: 'radius/management',
  },
  {
    id: 'azta',
    name: 'Android Zerotouch Assignment',
    //menu: <AZTAMenu />,
    icon: <PhoneLinkSetupRegular />,
    uri: 'azta/management',
  },
];

const useStyles = makeStyles({
  innerWrapper: {
    columnGap: "15px",
    display: "flex",
  },
  outerWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1px",
  },
  buttonWidth: {
    //width: "200px",
    justifyContent: "left",
    ...shorthands.padding("5px"),
  }
});

export const Navigation = () => {
  const {clientprincipal} = useClientprincipal()
  const navigate = useNavigate();
  const styles = useStyles();

/*
      <Accordion multiple collapsible>
        { 
          services
            .filter(service => clientprincipal?.roles?.includes(service['id']))
            .map((service,index) => (
              <AccordionItem value={index+1}>
                <AccordionHeader icon={service.icon}>{service.name}</AccordionHeader>
                <AccordionPanel>
                {service.menu}
                </AccordionPanel>
              </AccordionItem>
            ))
        }
      </Accordion>

*/

  return (
    <div className={styles.outerWrapper}>
    {
      (clientprincipal?.isAuthenticated && Array.isArray(services)) && (
        services
          .filter(service => clientprincipal?.roles?.includes(service['id']))
          .map((service,index) => (
            <CompoundButton
              className={styles.buttonWidth}
              shape="square"
              appearance="subtle"
              //appearance="outline"
              icon={service.icon}
              size="small"
              onClick={() => navigate(service.uri)}
            >
            {service.name}
          </CompoundButton>
          ))
      )
    }
    </div>
  );
}
