import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import * as React from "react";

import { 
  Button,
  Text,
  makeStyles,
  shorthands,
  tokens,
  Switch,
  SwitchProps,
} from "@fluentui/react-components";
import { 
  Dismiss24Regular,
  ArrowSync24Regular,
  Edit24Regular,
  BookInformation24Regular,
} from "@fluentui/react-icons";
import {
  useApplicationContext as useContext,
  IApplicationItem,
  IWinGetApplicationItem
} from '../../Context/ApplicationContext'
import type { ILogItem } from "../../Context/ApplicationContext";

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

// Open Application Drawer
export const OpenApplication = (props: IApplicationItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [general, setGeneral] = React.useState<boolean>(props['isGeneral']);
  const [update, setUpdate] = React.useState<boolean>(props['isUpdate']);
  const [preview, setPreview] = React.useState<boolean>(props['isPreview']);
  const {setApplication, synchronizeApplication} = useContext();
  const [syncTriggered, setSyncTriggered] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  const onClickApplyButton = React.useCallback(() => {
    if (  props['isGeneral'] !== general ||
          props['isPreview'] !== preview ||
          props['isUpdate'] !== update) {

      console.log('Set: appid ('+props.id+')')
      setApplication(
        {
          id: props.id,
          isGeneral: general,
          isUpdate: update,
          isPreview: preview,
        }
      )
    }
    
    setIsOpen(false);
  }, [general, preview, update, props, setApplication]);

  const General = (props: SwitchProps) => (
    <Switch label={"General"} checked={general} onChange={() => setGeneral(!general)} {...props} />
  );

  const Update = (props: SwitchProps) => (
    <Switch label={"Update"} checked={update} onChange={() => setUpdate(!update)} {...props} />
  );
  const Preview = (props: SwitchProps) => (
    <Switch label={"Preview"} checked={preview} onChange={() => setPreview(!preview)} {...props} />
  );

  const syncApplication = React.useCallback(() => {
    console.log('syncing: appid ('+props.id+')')
    setSyncTriggered(true)
    synchronizeApplication(
      {
        id: props.id,
        isGeneral: general,
        isUpdate: update,
        isPreview: preview,
      }
    )
  }, [general, preview, update, props.id, synchronizeApplication]);

  const SyncButton = React.useCallback(() => {
    return (
      <div>
        <Button
          shape="square"
          appearance="subtle"
          aria-label="Synchronize Application"
          icon={<ArrowSync24Regular />}
          onClick={syncApplication}
          size="medium"
          disabled={syncTriggered}
        >{(syncTriggered) ? ("Application synchronization triggered") : ("Synchronize Application")}</Button>
      </div>
    )  
  }, [syncTriggered, syncApplication])

  const styles = useStyles();

  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            { 
              props.displayName // Application details
            }
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                    { 
                      // Display Icon if present
                      (props.largeIcon) && (
                        <img src={`data:${JSON.parse(props.largeIcon).type};base64,${JSON.parse(props.largeIcon).value}`} width="150px" height="auto" alt="logo" />
                      )
                    }
                    <tr><td>Name</td><td>{props.displayName}</td></tr>
                    <tr><td>Vedor</td><td>{props.vendor}</td></tr>
                    <tr><td>Version</td><td>{props.generalVersion}</td></tr>
                    <tr><td>Preview version</td><td>{props.previewVersion}</td></tr>
                    <tr><td>Type</td><td>{(props.isPublic)?('Standard Application'):('Custom Application')}</td></tr>
                    <br></br>
                    <tr><td><General /></td></tr>
                    <tr><td><Update /></td></tr>
                    <tr><td><Preview /></td></tr>
                    <tr><td></td></tr>
                    {
                      (general || update || preview) && (
                        <tr><td><SyncButton /></td></tr>
                      )
                    }
                  </tbody>
                </table>
                <br></br>
                <br></br>
                {props.description}
              </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};

export const OpenWinGetApp = (props: IWinGetApplicationItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {provisionWinGetApp} = useContext();
  const [syncTriggered, setSyncTriggered] = React.useState(false);
  const applyStyles = useApplyButtonStyles();
  const styles = useStyles();

  const onClickCloseButton = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const provisionApplication = React.useCallback(() => {
    console.log('syncing: appid ('+props.id+')')
    setSyncTriggered(true)
    provisionWinGetApp(
      {
        id: props.id,
      }
    )
  }, [props.id, provisionWinGetApp]);

  const ProvisionButton = React.useCallback(() => {
    return (
      <Button
        shape="square"
        appearance="subtle"
        aria-label="Provision Application"
        icon={<ArrowSync24Regular />}
        onClick={provisionApplication}
        size="medium"
        disabled={syncTriggered}
      >{(syncTriggered) ? ("Application Provisioned") : ("Provision Application")}</Button>
    )  
  }, [syncTriggered, provisionApplication])


  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            { 
              props.displayName // Application details
            }
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              <div role="tabpanel" aria-labelledby="item">
                { 
                  // Display Icon if present
                  (props.largeIcon) && (
                    <img src={`data:${JSON.parse(props.largeIcon).type};base64,${JSON.parse(props.largeIcon).value}`} width="150px" height="auto" alt="logo" />
                  )
                }
                <table className={styles.propsTable}>
                  <tbody>
                    <tr><td>Name</td><td>{props.displayName}</td></tr>
                    <tr><td>Vedor</td><td>{props.vendor}</td></tr>
                  </tbody>
                </table>
                <br></br>
                <br></br>
                <ProvisionButton />
                <br></br>
                <br></br>
                {props.description}
                <br></br>
              </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<Edit24Regular />} />
    </div>
  );
};

export const OpenLogItem = (props: ILogItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const applyStyles = useApplyButtonStyles();

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  const styles = useStyles();
  const propertyFilter = [
  'odata.type',
  'odata.id',
  'odata.etag',
  'odata.editLink',
  'PartitionKey',
  'RowKey',
  'Timestamp@odata.type',
  'id@odata.type',
  ]
   
  return (
    <div>
      <DrawerOverlay
        open={isOpen}
        position="right"
        size="large"
        onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Log details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>
          <Text font="monospace">
              {
                // drawerContent
              }

              <div role="tabpanel" aria-labelledby="item">
                <table className={styles.propsTable}>
                  <tbody>
                    {
                      Object.entries(props).map(([key, value]) => !propertyFilter.includes(key) ? (
                        ( value === true || value === false ) ? (
                              <tr><td>{key}</td><td>{value.toString()}</td></tr>
                          ):(
                            <tr><td>{key}</td><td>{value}</td></tr>
                          )
                        ) : (
                          <></>
                        )
                      )
                    }
                  </tbody>  
                </table>
              </div>
          </Text>
          </p>
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickCloseButton}>Close</Button>
          </div>
        </DrawerBody>
      </DrawerOverlay>
      <Button appearance="transparent" onClick={() => setIsOpen(true)} icon={<BookInformation24Regular />} />
    </div>
  );
};