import * as React from "react";
import {
  ArrowSync24Regular,
  Dismiss24Regular,
  PresenceAvailable24Regular,
  PresenceBlocked24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
} from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Button, tokens } from "@fluentui/react-components";
import { Checkbox } from "@fluentui/react-components";
import { TextColumnThree24Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import { Field } from "@fluentui/react-components";
import {OpenAsset as OpenItem} from './Drawer'
import { 
  useAssetContext as useContext,
  IAssetItem as IGridItem
} from '../../Context/AssetContext'

const defaultColumns = ['deviceName','serialNumber','productName','manufacturer','deviceStatus','actions'];
const searchPropertyName = 'deviceName'
const sortAscending = true
const sortColumn = "deviceName"

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
          <OpenItem {...item} />
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "id",
    compare: (a, b) => {
      if (!a.id) {
        return -1;
      } 
      
      else if (!b.id) {
        return +1;
      }

      else {
        return a.id.localeCompare(b.id);
      }
      
    },
    renderHeaderCell: () => {
      return "Id";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.id}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "deviceName",
    compare: (a, b) => {
      if (!a.deviceName) {
        return -1;
      } 
      
      else if (!b.deviceName) {
        return +1;
      }

      else {
        return a.deviceName.localeCompare(b.deviceName);
      }
      
    },
    renderHeaderCell: () => {
      return "Device Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.deviceName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "autopilotEnrolled",
    compare: (a, b) => {
      //return a.autopilotEnrolled.localeCompare(b.autopilotEnrolled);
      //compare: (a, b) => {
      //  if (!a.DevicePresent) {
      //    return -1;
      //  } 
      //  else if (!b.DevicePresent) {
      //    return +1;
      //  }
      //  else {
      //    return a.DevicePresent.toString().localeCompare(b.DevicePresent.toString());
      //  }
      return (a.autopilotEnrolled === b.autopilotEnrolled)? 0 : a? -1 : 1;
    },
    renderHeaderCell: () => {
      return "Autopilot Enrolled";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.autopilotEnrolled === true ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "deviceRegistrationState",
    compare: (a, b) => {
      return a.deviceRegistrationState.localeCompare(b.deviceRegistrationState);
    },
    renderHeaderCell: () => {
      return "Device Registration State";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          { item.deviceRegistrationState
          
            //item.deviceRegistrationState === "registered" ? (
            //  <PresenceAvailable24Regular color="Green"/>
            //) : (
            //  <PresenceBlocked24Regular color="Red"/>
            //)
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "deviceStatus",
    compare: (a, b) => {
      return a.deviceStatus.localeCompare(b.deviceStatus);
    },
    renderHeaderCell: () => {
      return "Device Status (Active)";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            item.deviceStatus
          //item.deviceStatus === "Aktiv" ? (
          //    <PresenceAvailable24Regular color="Green"/>
          //  ) : (
          //    <PresenceBlocked24Regular color="Red"/>
          //  )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "deviceType",
    compare: (a, b) => {
      return a.deviceType.localeCompare(b.deviceType);
    },
    renderHeaderCell: () => {
      return "Device Type";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            item.deviceType
          //item.deviceStatus === "Aktiv" ? (
          //    <PresenceAvailable24Regular color="Green"/>
          //  ) : (
          //    <PresenceBlocked24Regular color="Red"/>
          //  )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "enrolledDateTime",
    compare: (a, b) => {
      return Date.parse(a.enrolledDateTime).valueOf() - Date.parse(b.enrolledDateTime).valueOf()
    },
    renderHeaderCell: () => {
      return "Enrolled DateTime";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            // Date.parse(item.Timestamp).valueOf()
            item.enrolledDateTime
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "firstEnrolledDateTime",
    compare: (a, b) => {
      return Date.parse(a.firstEnrolledDateTime).valueOf() - Date.parse(b.firstEnrolledDateTime).valueOf()
    },
    renderHeaderCell: () => {
      return "First Enrolled DateTime";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            // Date.parse(item.Timestamp).valueOf()
            item.firstEnrolledDateTime
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "lastAssignedUser",
    compare: (a, b) => {
      
      return a.lastAssignedUser.localeCompare(b.lastAssignedUser);
    },
    renderHeaderCell: () => {
      return "Last Assigned User";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.lastAssignedUser}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "locationId",
    compare: (a, b) => {
      return a.locationId.localeCompare(b.locationId);
    },
    renderHeaderCell: () => {
      return "Location Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.locationId}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "notes",
    compare: (a, b) => {
      return a.notes.localeCompare(b.notes);
    },
    renderHeaderCell: () => {
      return "Location Notes";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.notes}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "manufacturer",
    compare: (a, b) => {
      return a.manufacturer.localeCompare(b.manufacturer);
    },
    renderHeaderCell: () => {
      return "Manufacturer";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.manufacturer}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "model",
    compare: (a, b) => {
      return a.model.localeCompare(b.model);
    },
    renderHeaderCell: () => {
      return "model";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.model}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "operatingSystem",
    compare: (a, b) => {
      return a.operatingSystem.localeCompare(b.operatingSystem);
    },
    renderHeaderCell: () => {
      return "Operating System";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.operatingSystem}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "productName",
    compare: (a, b) => {
      return a.productName.localeCompare(b.productName);
    },
    renderHeaderCell: () => {
      return "Product Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.productName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "serialNumber",
    compare: (a, b) => {
      return a.serialNumber.localeCompare(b.serialNumber);
    },
    renderHeaderCell: () => {
      return "Serial Number";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.serialNumber}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "userDisplayName",
    compare: (a, b) => {
      return a.userDisplayName.localeCompare(b.userDisplayName);
    },
    renderHeaderCell: () => {
      return "User Displayname";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.userDisplayName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "userPrincipalName",
    compare: (a, b) => {
      return a.userPrincipalName.localeCompare(b.userPrincipalName);
    },
    renderHeaderCell: () => {
      return "User Principalname";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.userPrincipalName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "warrantyStatus",
    compare: (a, b) => {
      return a.warrantyStatus.localeCompare(b.warrantyStatus);
    },
    renderHeaderCell: () => {
      return "Warranty Status";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.warrantyStatus
          //  item.DeviceComplianceState === "compliant" ? (
          //    <PresenceAvailable24Regular color="Green"/>
          //  ) : (
          //    <PresenceBlocked24Regular color="Red"/>
          //  )
          }

        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  Response: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DevicePresent: {
      minWidth: 60,
      defaultWidth: 80,
  },
  DeviceComplianceState: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DeviceSerialNumber: {
    minWidth: 80,
    defaultWidth: 100,
  },
  Timestamp: {
    defaultWidth: 250,
    minWidth: 120,
    idealWidth: 250,
  },
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const {assets, locations, isLoadingAssets, refreshAssets} = useContext();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns
      .includes(o.columnId.toString())
    )))
  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  // Refresh Assets (isLoadingAssets)
  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        {
          isLoadingAssets ? (
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular className="Spinner-icon" />}
              size="medium"
            >Refresh</Button>
          ) : (
              <Button
                shape="square"
                appearance="subtle"
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={refreshAssets}
                size="medium"
              >Refresh</Button>
          )
        }
      </div>
    )
  }

  // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter (deviceName)
  React.useEffect(() => {
    // Check that both the assets and locations array is present before .
    if (Array.isArray(assets) && Array.isArray(locations)) {
      if(searchFilter !== "") {
        setFilteredItems(
          assets.filter(item => 
            (
              (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
            )
          )
        )
      } else {
        setFilteredItems(assets)
      }
    } else {
      setFilteredItems([])
    }
  },[searchFilter, assets, locations])
  
  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IGridItem>;
    }
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    const handleCheckBox = React.useCallback((index:number) => {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    },[columnsRef])
    
    const onClickApplyButton = React.useCallback(() => {
      setIsOpen(false);
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
    }, [columnsRef]);

    const onClickColumnsButton = React.useCallback(() => {
      setIsOpen(true);
    }, []);

    return (
      <div>
        <DrawerOverlay
          position="right"
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <Checkbox
              checked={
                  columnsRef.every( o => o.enabled === true ) ? true : columnsRef.every( o => o.enabled === false ) ? false : "mixed"
              }
              onChange={(_ev, data) => {
                const nextColumnsRef = columnsRef
                    .map((o) => {
                      return {
                        name: o.name,
                        enabled: !!data.checked,
                        column: o.column
                      }
                    });

                setColumnsRef(
                  nextColumnsRef
                )
              }}
              label="All columns"
            />
            <br></br>
            {
              columnsRef.map((o, index) =>
                <div>
                <Checkbox
                  key={index}
                  checked={o.enabled}
                  onChange={() => handleCheckBox(index)}
                  label={o.name}
                /><br></br>
                </div>
              )
            }
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            </div>
          </DrawerBody>
        </DrawerOverlay>
        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickColumnsButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };
  
  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: sortColumn, sortDirection: (sortAscending) ? ("ascending") : ("descending")}), []
  );

  // GridArea (isLoading)
  const GridArea = React.useCallback(() => {
    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.Timestamp}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          {
            (!isLoadingAssets) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <Spinner label="Loading..." labelPosition="below"/>
            )
          }
        </DataGrid>
      </div>
    )
  },[filteredItems,showColumns,isLoadingAssets,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+assets.length+")" : "Input is limited to 20 characters."}
        >
          <tr>
            <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
            <td><RefreshButton /></td>
            <td><ColumnsDrawer /></td>
          </tr>
        </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;