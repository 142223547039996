import * as React from "react";
import { default as TabsWithPanels } from './TabsWithPanels'
import { ServiceProvider as Provider} from '../../Context/ServiceContext'

export const WithPanels = () => {
  return (
    <Provider>
        <TabsWithPanels />
    </Provider>
  );
};

export default WithPanels;
