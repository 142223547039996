import * as React from "react";
import {
  Tab,
  TabList,
  SpinnerProps,
  Spinner,
} from "@fluentui/react-components";
import {
  PeopleList24Regular,
  PeopleList24Filled,
  Settings24Filled,
  Settings24Regular,
  Certificate24Regular,
  Certificate24Filled,
  DatabaseSearch24Regular,
  DatabaseSearch24Filled,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as Faq } from './Faq'
import { default as Settings } from './Settings'
import {default as SubscriptionsManagement} from './SubscriptionsManagement'
import {default as GroupsManagement} from './GroupsManagement'
import {default as Logs} from './Logs'
import { useMSCSPContext } from "../../Context/MSCSPContext";

const SettingsIcons = bundleIcon(Settings24Filled, Settings24Regular)
const FaqIcons = bundleIcon(DocumentOnePageMultiple24Filled, DocumentOnePageMultiple24Regular);
const LogsIcons = bundleIcon(DatabaseSearch24Filled, DatabaseSearch24Regular);
const SubscriptionsIcons = bundleIcon(Certificate24Filled, Certificate24Regular)
const GroupIcons = bundleIcon(PeopleList24Filled, PeopleList24Regular)

export const DefaultSpinner = (props: Partial<SpinnerProps>) => <Spinner {...props} />;

export const TabsWithPanels = () => {
  const {subscription} = useMSCSPContext();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>('default')

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const SettingsPanel = React.memo(() => (
    <Settings />
  ));
  
  const FaqPanel = React.memo(() => (
    <Faq />
  ));

  const LogsPanel = React.memo(() => (
    <Logs />
  ));

  const SubscriptionsManagementPanel = React.memo(() => (
    <SubscriptionsManagement />
  ));
  
  const GroupsManagementPanel = React.memo(() => (
    <GroupsManagement />
  ));
  
  const ActiveTabs = React.useCallback(() => {
    return (
      <>
      {(subscription?.isEnabled) ? (
        <>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Settings" icon={<SettingsIcons />} value="settings">
              Settings
            </Tab>
            <Tab id="FAQ" icon={<FaqIcons />} value="faq">
              FAQ
            </Tab>
            <Tab id="Logs" icon={<LogsIcons />} value="logs">
              Logs
            </Tab>
            <Tab id="Subscriptions" icon={<SubscriptionsIcons />} value="subscriptions">
              Subscriptions
            </Tab>
            <Tab id="Groups" icon={<GroupIcons />} value="groups">
              Groups
            </Tab>
          </TabList>
            <div className="App-contentgrid">
              {selectedValue === "default" && <SubscriptionsManagementPanel />}
              {selectedValue === "faq" && <FaqPanel />}
              {selectedValue === "settings" && <SettingsPanel />}
              {selectedValue === "logs" && <LogsPanel />}
              {selectedValue === "subscriptions" && <SubscriptionsManagementPanel />}
              {selectedValue === "groups" && <GroupsManagementPanel />}
          </div>
        </>
      ) : (
        <>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Settings" icon={<SettingsIcons />} value="settings">
              Settings
            </Tab>
            <Tab id="FAQ" icon={<FaqIcons />} value="faq">
              FAQ
            </Tab>
          </TabList>
          <div className="App-contentgrid">
            {selectedValue === "default" && <FaqPanel />}
            {selectedValue === "faq" && <FaqPanel />}
            {selectedValue === "settings" && <SettingsPanel />}
          </div>
        </>
      )}
      </>    
    )
  },[subscription, selectedValue, FaqPanel, GroupsManagementPanel, LogsPanel, SettingsPanel, SubscriptionsManagementPanel])

  return (
    (!subscription) ? (
      <DefaultSpinner />
    ) : (
      <ActiveTabs />
    )
  );
};

export default TabsWithPanels;
