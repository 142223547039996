import * as React from "react";
import {
  //EditRegular,
  Filter24Regular,
  PresenceAvailable24Regular,
  PresenceBlocked24Regular,
  ArrowSync24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
} from "@fluentui/react-components";
import { 
  makeStyles,
  //shorthands
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
  DrawerProps,
} from "@fluentui/react-components/unstable";

import { Button, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

import { Checkbox } from "@fluentui/react-components";

import { TextColumnThree24Regular } from "@fluentui/react-icons";

import { SearchBox } from "@fluentui/react-search-preview";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import { Field } from "@fluentui/react-components";

//import { EditFilled, bundleIcon } from "@fluentui/react-icons";
import { useRadiusContext } from "../../Context/RadiusContext";
import type { IRadiusLogItem } from "../../Context/RadiusContext";
import {OpenItem} from './Drawer'

//const EditIcon = bundleIcon(EditFilled, EditRegular);

export const Placeholder = () => {  
  return (
      <SearchBox placeholder="Search" />
  );
};

export const Controlled = () => {
  const [value, setValue] = React.useState("initial value");
  const [valid, setValid] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setValue(data.value);
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <Field
      label="Controlled SearchBox limiting the value to 20 characters"
      validationState={valid ? "none" : "warning"}
      validationMessage={valid ? "" : "Input is limited to 20 characters."}
    >
      <SearchBox value={value} onChange={onSearchBoxChange} />
    </Field>
  );
};

/*
const useStyles = makeStyles({
  container: {
    "> div": { ...shorthands.padding("2px","0px") },
  },
});
*/

const columns: TableColumnDefinition<IRadiusLogItem>[] = [
  createTableColumn<IRadiusLogItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      // <Button aria-label="Delete" icon={<DeleteRegular />} />
      return (
        <>
          {
            //<Button aria-label="Edit" icon={<EditRegular />} />
          }
          <OpenItem {...item} />
        </>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "AuthServer",
    compare: (a, b) => {
      if (!a.AuthServer) {
        return -1;
      } 
      
      else if (!b.AuthServer) {
        return +1;
      }

      else {
        return a.AuthServer.localeCompare(b.AuthServer);
      }
      
    },
    renderHeaderCell: () => {
      return "Auth Server";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.AuthServer}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "LogDateTime",
    compare: (a, b) => {
      return Date.parse(a.LogDateTime).valueOf() - Date.parse(b.LogDateTime).valueOf()
    },
    renderHeaderCell: () => {
      return "Timestamp";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            item.LogDateTime
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "Response",
    compare: (a, b) => {
      
      return a.Response.localeCompare(b.Response);
    },
    renderHeaderCell: () => {
      return "Response";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.Response === "Access-Accept" ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "DeviceSerialNumber",
    compare: (a, b) => {
      return a.DeviceSerialNumber.localeCompare(b.DeviceSerialNumber);
    },
    renderHeaderCell: () => {
      return "Device Serialnumber";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceSerialNumber}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "DevicePresent",
    compare: (a, b) => {
      if (!a.DevicePresent) {
        return -1;
      } 
      
      else if (!b.DevicePresent) {
        return +1;
      }

      else {
        return a.DevicePresent.toString().localeCompare(b.DevicePresent.toString());
      }
    },
    renderHeaderCell: () => {
      return "Device Present";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {(item.DevicePresent) ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "DeviceComplianceState",
    compare: (a, b) => {
      return a.DeviceComplianceState.localeCompare(b.DeviceComplianceState);
    },
    renderHeaderCell: () => {
      return "Device Compliance";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceComplianceState === "compliant" ? (
              <PresenceAvailable24Regular color="Green"/>
            ) : (
              <PresenceBlocked24Regular color="Red"/>
            )}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "CalledStationSSID",
    compare: (a, b) => {
      return a.CalledStationSSID.localeCompare(b.CalledStationSSID);
    },
    renderHeaderCell: () => {
      return "SSID";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.CalledStationSSID}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "DeviceOperatingSystem",
    compare: (a, b) => {
      return a.DeviceOperatingSystem.localeCompare(b.DeviceOperatingSystem);
    },
    renderHeaderCell: () => {
      return "Operating System";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DeviceOperatingSystem}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IRadiusLogItem>({
    columnId: "TlsClientCertIssuer",
    compare: (a, b) => {
      return a.TlsClientCertIssuer.localeCompare(b.TlsClientCertIssuer);
    },
    renderHeaderCell: () => {
      return "Tls Client Cert Issuer";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.TlsClientCertIssuer}
        </TableCellLayout>
      );
    },
  }),
];


const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  LogDateTime: {
    defaultWidth: 250,
    minWidth: 120,
    idealWidth: 250,
  },
  Response: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DevicePresent: {
      minWidth: 60,
      defaultWidth: 80,
  },
  DeviceComplianceState: {
    minWidth: 60,
    defaultWidth: 80,
  },
  DeviceSerialNumber: {
    minWidth: 80,
    defaultWidth: 100,
  },
};

//Drawer
const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const ResizableColumns = () => {
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const {items, isLoading, refreshItems} = useRadiusContext();
  const [filteredItems, setFilteredItems] = React.useState<IRadiusLogItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IRadiusLogItem>[]>(
    columns.filter(o => (
      ['actions','LogDateTime','Response','DeviceSerialNumber','DevicePresent','DeviceComplianceState','CalledStationSSID','DeviceOperatingSystem'] //Default menu
      .includes(o.columnId.toString())
    )))
  //const styles = useStyles();

  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

    const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        {
          isLoading ? (
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular className="Spinner-icon" />}
              size="medium"
            >Refresh</Button>
          ) : (
            <Button
                shape="square"
                appearance="subtle"
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={refreshItems}
                size="medium"
              >Refresh</Button>
          )
        }
      </div>
    )
  }

  interface IfilterSSID {
    name: string;
    enabled: boolean;
  }

  const [filterSSID, setFilterSSID] = React.useState<IfilterSSID[]>([])

  // SSID Filter
  React.useEffect(() => {
    // const ssid = [...new Set(items.map((item) => item.CalledStationSSID))]
    setFilterSSID(
      [...new Set(items.map((item) => item.CalledStationSSID))]
      .map(o => ({
        name: o.toString(),
        enabled: true
      })) as IfilterSSID[]
    )
  },[items])

    // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter
  React.useEffect(() => {
    if(searchFilter !== "") {
      setFilteredItems(
        items.filter(item => 
          ((item.DeviceSerialNumber.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1)
          && 
          (filterSSID.filter(o => (o.enabled)).map(o => (o.name)).includes(item.CalledStationSSID))
         )
        )
      )
    } else if(Array.isArray(filterSSID)) {
      setFilteredItems(
        items.filter(item => 
          (filterSSID.filter(o => (o.enabled)).map(o => (o.name)).includes(item.CalledStationSSID))
        )
      )
    } else {
      setFilteredItems(items)
    }
  },[filterSSID, searchFilter, items])

  // SSID Filter Drawer
  const SsidDrawer = () => {
    const [ssidRef, setSsidRef] = React.useState<IfilterSSID[]>(filterSSID)
    
    function handleCheckBox(index:number) {
      const nextCounters = ssidRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setSsidRef(nextCounters);
    }
    
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();

    React.useEffect(() => {
      setSsidRef(ssidRef)
    },[ssidRef])

    const [isOpen, setIsOpen] = React.useState(false);
    const [position, setPosition] =
      React.useState<DrawerProps["position"]>("right");
    
    const onClickApplyButton = React.useCallback(() => {
      setFilterSSID(ssidRef);
      setIsOpen(false);
    }, [ssidRef]);
    
    //const onClickLeftButton = React.useCallback(() => {
    //  setPosition("left");
    //  setIsOpen(true);
    //}, []);

    const onClickRightButton = React.useCallback(() => {
      setPosition("right");
      setIsOpen(true);
    }, []);

    return (
      <div>
        <DrawerOverlay
          position={position}
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              {
                //position === "left" ? "Left" : "Right"
              } Select SSID´s
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
          <Checkbox
          checked={
              ssidRef.every(
                o => o.enabled === true
              )
              ? true
              : ssidRef.every(
                  o => o.enabled === false
                )
              ? false
              : "mixed"
          }
          onChange={(_ev, data) => {
            const nextSsidRef = ssidRef
                .map((o) => {
                  return {
                    name: o.name,
                    enabled: !!data.checked,
                  }
                });

            setSsidRef(
              nextSsidRef
            )
          }}
          label="All SSID´s"
        />
        <br></br>
        { ssidRef.map((o, index) =>
            <div>
            <Checkbox
              key={index}
              checked={o.enabled}
              onChange={() => handleCheckBox(index)}
              label={o.name}
            /><br/>
            </div>
          )
        }
        {
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        }
          </DrawerBody>
        </DrawerOverlay>

        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<Filter24Regular />} onClick={onClickRightButton}>
            SSID
          </Button>
        </div>
      </div>
    );
  };

  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IRadiusLogItem>;
    }

    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    function handleCheckBox(index:number) {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    }
    
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [position, setPosition] = React.useState<DrawerProps["position"]>("right");
    
    const onClickApplyButton = React.useCallback(() => {
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
      setIsOpen(false);
    }, [columnsRef]);
    const onClickRightButton = React.useCallback(() => {
      setPosition("right");
      setIsOpen(true);
    }, []);

    return (
      <div>
        <DrawerOverlay
          position={position}
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
          <Checkbox
          checked={
              columnsRef.every(
                o => o.enabled === true
              )
              ? true
              : columnsRef.every(
                  o => o.enabled === false
                )
              ? false
              : "mixed"
          }
          onChange={(_ev, data) => {
            const nextColumnsRef = columnsRef
                .map((o) => {
                  return {
                    name: o.name,
                    enabled: !!data.checked,
                    column: o.column
                  }
                });

            setColumnsRef(
              nextColumnsRef
            )
          }}
          label="All columns"
        />
        <br></br>
        {columnsRef.map((o, index) =>
          <div>
          <Checkbox
            key={index}
            checked={o.enabled}
            onChange={() => handleCheckBox(index)}
            label={o.name}
          /><br></br>
          </div>
        )}
        {
          <div className={applyStyles.content}>
            <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
          </div>
        }
          </DrawerBody>
        </DrawerOverlay>

        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickRightButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };

  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: "LogDateTime", sortDirection: "descending"}), []
  );

  // <div className={styles.container}>
  return (
    <div className="App-contentgrid">
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+items.length+")" : "Input is limited to 20 characters."}
        >
          <tr>
          <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
          <td><RefreshButton /></td>
          <td><ColumnsDrawer /></td>
          <td><SsidDrawer /></td>
          </tr>
        </Field>
      </div>
      <div className='App-tablearea'>
        { 
          Array.isArray(filteredItems) && filteredItems?.length > 0? (
            <DataGrid
              items={filteredItems}
              columns={showColumns}
              sortable
              defaultSortState={defaultSortState} // "ascending"
              noNativeElements
              getRowId={(item) => item.Timestamp}
              resizableColumns
              columnSizingOptions={columnSizingOptions}
            >
              <DataGridHeader>
                <DataGridRow>
                  {({ renderHeaderCell, columnId }, dataGrid) =>
                    dataGrid.resizableColumns ? (
                      <Menu openOnContext>
                        <MenuTrigger>
                          <DataGridHeaderCell
                            ref={(el) => (refMap.current[columnId] = el)}
                          >
                            {renderHeaderCell()}
                          </DataGridHeaderCell>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem
                              onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                                columnId
                              )}
                            >
                              Keyboard Column Resizing
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    ) : (
                      <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )
                  }
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody<IRadiusLogItem>>
                {({ item, rowId }) => (
                  <DataGridRow<IRadiusLogItem>
                    key={rowId}
                  >
                    {({ renderCell }) => (
                      <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
          ) : (
            <DataGrid
              items={filteredItems}
              columns={showColumns}
              sortable
              getRowId={(item) => item.Timestamp}
              resizableColumns
              columnSizingOptions={columnSizingOptions}
            >
              <DataGridHeader>
                <DataGridRow>
                  {({ renderHeaderCell, columnId }, dataGrid) =>
                    dataGrid.resizableColumns ? (
                      <Menu openOnContext>
                        <MenuTrigger>
                          <DataGridHeaderCell
                            ref={(el) => (refMap.current[columnId] = el)}
                          >
                            {renderHeaderCell()}
                          </DataGridHeaderCell>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem
                              onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                                columnId
                              )}
                            >
                              Keyboard Column Resizing
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    ) : (
                      <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )
                  }
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody<IRadiusLogItem>>
                {({ item, rowId }) => (
                  <DataGridRow<IRadiusLogItem>
                    key={rowId}
                  >
                    {({ renderCell }) => (
                      <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
          )
        }
        </div>
    </div>
  );
};

export default ResizableColumns;