import * as React from "react";

import {
  makeStyles,
  Body1,
  Caption1,
  Button,
  shorthands,
  tokens,
} from "@fluentui/react-components";
//import { ArrowReplyRegular, ShareRegular } from "@fluentui/react-icons";
import {
  Card,
  CardFooter,
  CardHeader,
  //CardPreview,
} from "@fluentui/react-components";
import { 
    Save24Regular,
} from "@fluentui/react-icons";
import {
    Switch,
    SwitchProps,
    //Spinner,
} from "@fluentui/react-components";
import { useAssetContext as useContext} from "../../Context/AssetContext";
import type { ISubscriptionItem } from "../../Context/AssetContext";


const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    marginTop: "30px",
    marginLeft: "30px",
  },
  description: {
    ...shorthands.padding("20px"),
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const ServiceCard = () => {
  const {serviceOffer, subscription, patchSubscription} = useContext();
  const styles = useStyles();
  const [subscriptionSettings, setSubscriptionSettings] = React.useState<ISubscriptionItem>(subscription || {tenantid: undefined, isEnabled: false});

  const onClickSaveButton = React.useCallback(() => {
    patchSubscription(
      {
        //tenantid:undefined,
        isEnabled: subscriptionSettings.isEnabled
      }
    )
  }, [subscriptionSettings, patchSubscription]);

  const SaveButton = React.useCallback(() => {
    return (
        (subscription && subscriptionSettings.isEnabled !== subscription?.isEnabled) ? (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton}>Save</Button>
        ) : (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" disabled>Save</Button>
        )

    )
  },[subscriptionSettings, subscription, onClickSaveButton])

  const SubscriptionEnabled = React.useCallback((props: SwitchProps) => (
    <Switch 
      //label={subscriptionSettings.isEnabled ? ("Enabled") : ("Disabled")}
      checked={subscriptionSettings.isEnabled}
      onChange={() => setSubscriptionSettings({
        ...subscriptionSettings,
        isEnabled: !(subscriptionSettings.isEnabled)
      })}
      {...props}
    />
  ),[subscriptionSettings]);


  return (
    <Card className={styles.card}>
      <CardHeader
        image = {
            <SubscriptionEnabled />
        }
        header={
            <Body1>
                <b>{serviceOffer?.displayname}</b>
            </Body1>
        }
        description={
            <Caption1>
                {`${serviceOffer?.retailprice} ${serviceOffer?.currencycode} per ${serviceOffer?.unittype}`}
            </Caption1>
        }
      />
        <div className={styles.description}>
          {serviceOffer?.description}
        </div>
      <CardFooter>
        <SaveButton />
      </CardFooter>
    </Card>
  );
};
