import logo from '../../logo.svg';
import '../../App.css';
import React from 'react';

function Start() {
  return (
    <div>
        <p className="App">
            <img src={logo} className="App-logo" alt="logo" />
        </p>
        <p className="App">
          RADIUS SERVICE START
        </p>
    </div>
  );
}
export default Start;