import React from "react"
import {useClientprincipal} from '../Context/ClientprincipalContext'

export interface ILineItem {
  billingPeriod: string;
  currencyCode: string;
  id: string;
  quantity: number;
  retailPrice: number;
  serviceId: string;
  totalPrice: number;
  unitPrice: number;
}

export interface IInvoiceItem {
  id: string;
  currencyCode: string;
  invoiceDate: string;
  invoiceNumber: number;
  invoiced: boolean;
  lineItems: ILineItem[];
  totalCharges: number;
}

/*
export interface IInvoiceLineItem {
  id: string;
  billingPeriod: string;
  quantity: string;
  retailPrice: string;
  totalPrice: string;
  unitPrice: string;
  serviceId: string;
  currencyCode: string;
  invoiceDate: string;
  invoiceNumber: string;
  invoiced: boolean;
  lineItems: ILineItem;
  totalCharges: string;
}
*/

export type ServiceContextType = {
  items: IInvoiceItem[];
  isLoading: boolean;
  refreshItems: () => void;
};

const ServiceDefaultValue = {
  items: [],
  isLoading: false,
  refreshItems: () => {},
};

export interface IProviderProps {
children?: any;
}

const ServiceContext = React.createContext<ServiceContextType>(ServiceDefaultValue)

export const useBillingContext = () => {
  const context = React.useContext(ServiceContext);
  if (!context) {
    throw new Error(
      `useServiceContext must be used within a ServiceProvider`
    )
  }
  return context;
}

export const ServiceProvider = (props: IProviderProps) => {
  const [items, setItems] = React.useState<IInvoiceItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const {clientprincipal} = useClientprincipal();
  
  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') && clientprincipal.tenantId){
      setIsLoading(true);
      
      // Get services 
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId
                  },
      };
      fetch('/api/acp/invoices', requestOptions)
      .then((response) => response.json())
      .then((result) => { setItems(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
    } else {
      setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId]);

  // Init service items array
  React.useEffect(() => {
    refreshItems();
  },[clientprincipal, refreshItems]);

  const value = React.useMemo(() => ({items, isLoading, refreshItems}), [items, isLoading, refreshItems])
  return <ServiceContext.Provider value={value} {...props} />
}
