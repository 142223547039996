import React from "react"

export interface IServiceOffer {
    id: string;
    displayname: string;
    name: string;
    description: string;
    currencycode: string;
    retailprice: string;
    unittype: string;
}

export interface IClientprincipal {
    isAuthenticated: boolean;
    response: any | null;
    tenantId?: string | null;
    userId?: string | null;
    roles?: string[] | null;
    userName?: string | null;
    displayName?: string | null;
    claims?: IClientprincipalClaim[] | null;
}

export interface IClientprincipalClaim {
    typ: string;
    val: string;
}

export type ClientprincipalContextType = {
    clientprincipal: IClientprincipal;
    serviceOffers: IServiceOffer[];
    refreshClientprincipal: () => void;
    DisposeClientprincipal: () => void;
};

export type IClientprincipalObject = {
    "identityProvider": string,
    "userId": string,
    "userDetails": string,
    "userRoles": string[],
    "claims": IClientprincipalClaim[],
}

export interface IProviderProps {
    children?: any;
}


const initialClientprincipalState = {
    isAuthenticated:    false,
    response:           null,
}

const ClientprincipalDefaultValue = {
    clientprincipal: initialClientprincipalState,
    serviceOffers: [],
    refreshClientprincipal: () => {},
    DisposeClientprincipal: () => {},
    //setClientprincipal: (theme: ITheme) => {} // noop default callback
  };

const ClientprincipalContext = React.createContext<ClientprincipalContextType>(ClientprincipalDefaultValue)

export const useClientprincipal = () => {
  const context = React.useContext(ClientprincipalContext);
  if (!context) {
    throw new Error(
        `useClientprincipal must be used within a ClientprincipalProvider`
    )
  }
  return context;
}
// props: ClientprincipalContextType
export const ClientprincipalProvider = (props: IProviderProps) => {
    const [clientprincipal, setClientprincipal] = React.useState<IClientprincipal>(initialClientprincipalState);
    const [serviceOffers, setServiceOffers] = React.useState<IServiceOffer[]>([]);

    //const refreshClientprincipal = React.useCallback(() => {
    const refreshClientprincipal  = () => {

        // Get Clientprincipal
        fetch('/.auth/me')
        .then((response) => response.json())
        .then(({ clientPrincipal }) => {
            if (!clientPrincipal) {
                setClientprincipal(initialClientprincipalState)
            } else {
                if (clientPrincipal !== clientprincipal.response) {
                    //alert ( "set clientprincipal")
                    setClientprincipal(
                        {
                            isAuthenticated:    true,
                            response:           clientPrincipal,
                            userName:           clientPrincipal['userDetails'],
                            displayName:       (clientPrincipal['claims'].find((element: IClientprincipalClaim) => {
                                                    return element.typ === "name";
                                                })).val,
                            userId:             clientPrincipal['userId'],
                            tenantId:           (clientPrincipal['claims'].find((element: IClientprincipalClaim) => {
                                                    return element.typ === "http://schemas.microsoft.com/identity/claims/tenantid";
                                                })).val,
                            roles:              clientPrincipal['userRoles'],
                            claims:             clientPrincipal['claims'],
                        }
                    )
                }
            }
               
        })
        .catch((error) => {
            console.log("An error occured")
            setClientprincipal(initialClientprincipalState)
        })
        .finally(() => {
            // x           
        })
    }
    //, [clientprincipal])

    const refreshServiceOffers = React.useCallback(() => {
        if(clientprincipal?.isAuthenticated && clientprincipal.tenantId){
          
          // Get services 
          const requestOptions = {
            method: 'GET',
            headers: {  'Content-Type': 'application/json',
                        'tenantId': clientprincipal.tenantId
                      },
          };
          fetch('/api/services/offers', requestOptions)
          .then((response) => response.json())
          .then((result) => {setServiceOffers(result);console.log(result)})
          .catch((error) => console.log("An error occured"))
          .finally(() => {
          })
        }
      }, [clientprincipal?.isAuthenticated, clientprincipal?.tenantId]);

    // Dispose clientprincipal (signout)
    const DisposeClientprincipal = React.useCallback(() => {
        setClientprincipal(initialClientprincipalState)
    }, []);

    React.useEffect(() => {
        refreshClientprincipal();
    },[]);

    React.useEffect(() => {
        refreshServiceOffers();
    },[refreshServiceOffers,clientprincipal]);

    const value = React.useMemo(() => ({clientprincipal, serviceOffers, refreshClientprincipal, DisposeClientprincipal}), [clientprincipal, serviceOffers, refreshClientprincipal, DisposeClientprincipal])
    return <ClientprincipalContext.Provider value={value} {...props} />;

}
