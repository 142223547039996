import React from "react"
import {useClientprincipal} from './ClientprincipalContext'
import type { IServiceOffer } from './ClientprincipalContext'

export interface ISubscriptionItem {
  tenantid: string | undefined;
  isEnabled: boolean | undefined;
}

export interface IRadiusLogItem {
    "id": string;
    "AuthServer": string;
    "CalledStationSSID": string;
    "DeviceComplianceState": string;
    "DeviceId": string;
    "DeviceName": string;
    "serialNumber": string;
    "operatingSystem": string;
    "DeviceOperatingSystem": string;
    "DevicePresent": boolean,
    "DeviceSerialNumber": string;
    "LogDateTime": string;
    "Reason": string;
    "Response": string;
    "TenantId": string;
    "Timestamp": string;
    "TlsClientCertCommonName": string;
    "TlsClientCertIssuer": string;
    "TlsClientCertOid14": string;
    "UserName": string;
    "ListenTlsClientCertCommonName": string;
}

export type RadiusContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: ISubscriptionItem | undefined;
  items: IRadiusLogItem[];
  isLoading: boolean;
  caCertificateDownload: () => void;
  clientCertificateDownload: () => void;
  refreshItems: () => void;
  refreshSubscription: () => void;
  patchSubscription: (props: Partial<ISubscriptionItem>) => void;
};

export interface IProviderProps {
  children?: any;
}

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

const RadiusDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  items: [],
  isLoading: false,
  caCertificateDownload: () => {},
  clientCertificateDownload: () => {},
  refreshItems: () => {},
  refreshSubscription: () => {},
  patchSubscription: (props: Partial<ISubscriptionItem>) => {},
};

const RadiusContext = React.createContext<RadiusContextType>(RadiusDefaultValue)

export const useRadiusContext = () => {
  const context = React.useContext(RadiusContext);
  if (!context) {
    throw new Error(
      `useRadiusContext must be used within a RadiusProvider`
    )
  }
  return context;
}

export const base64toblob = (props: {Base64String: string; fileName: string; type: string;}) => {
  console.log('Base64String: '+props.Base64String)
  console.log('fileName: '+props.fileName)
  console.log('type: '+props.type)
  const ByteArray = Uint8Array.from(atob(props.Base64String), c => c.charCodeAt(0))
  
  const blob = new Blob([ByteArray], {
      type: props.type //'application/x-x509-ca-cert' or 'application/x-pkcs12'
    });

  const blobUri = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobUri;
  a.download = props.fileName;
  a.click();
  a.remove();
}

export const RadiusProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<ISubscriptionItem | undefined>(undefined);
  const [items, setItems] = React.useState<IRadiusLogItem[]>([]);
  const [clientCertificateAsBase64String, setClientCertificateAsBase64String] = React.useState<string>('');
  const [caCertificateAsBase64String, setCaCertificateAsBase64String] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false)
  const {clientprincipal, serviceOffers} = useClientprincipal()
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);

  const refreshSubscription = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && typeof clientprincipal?.tenantId === 'string'){
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/radius/subscription', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISubscriptionItemValue) : (undefined))))
      .then((result) => {setSubscription(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
      
    } else {
      // setSubscription(DefaultISubscriptionItemValue)
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);
  
  const patchSubscription = React.useCallback((props: Partial<ISubscriptionItem>) => {
    console.log('patchSubscription')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(subscription)
      console.log('isEnabled: ' + props.isEnabled)
      // Update Subscription
      console.log('Updating subscription')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          isEnabled: props.isEnabled,
        }),
      };
      fetch('/api/radius/subscription', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshSubscription()
      ) : (
        console.log('Failed to set subscription.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshSubscription()
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, subscription, refreshSubscription])

  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && clientprincipal.tenantId){
      
      // Get Items
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/radius/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => setItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
      
    } else {
      setItems([])
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId])

  const refreshClientCertificate = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && clientprincipal.tenantId){
      const uri = '/api/radius/certificates'
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
      };

      fetch(uri, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        console.log(payload)
        setClientCertificateAsBase64String(payload.ClientCertificate);
        setCaCertificateAsBase64String(payload.CaCertificate);
      })
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId])

  const clientCertificateDownload = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && clientprincipal.tenantId){
      base64toblob({Base64String:clientCertificateAsBase64String,fileName:'RadiusClientCertificate.pfx',type:'application/x-pkcs12'})
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientCertificateAsBase64String])

  const caCertificateDownload = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('radius') && clientprincipal.tenantId){
      base64toblob({Base64String:caCertificateAsBase64String,fileName:'RadiusCaCertificate.cer',type:'application/x-x509-ca-cert'})
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, caCertificateAsBase64String])

  React.useEffect(() => {
    refreshItems();
  }, [clientprincipal,refreshItems]);

  React.useEffect(() => {
    refreshClientCertificate();
  }, [clientprincipal,refreshClientCertificate]);

  React.useEffect(() => {
    refreshSubscription();
  },[clientprincipal, refreshSubscription]);

  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "radius";
      }))
  },[serviceOffers, setServiceOffer]);

  const value = React.useMemo(() => ({serviceOffer, subscription, items, isLoading, refreshSubscription, patchSubscription, clientCertificateDownload, caCertificateDownload, refreshItems}), [serviceOffer, subscription, items, isLoading, refreshSubscription, patchSubscription, clientCertificateDownload, caCertificateDownload, refreshItems])
  return <RadiusContext.Provider value={value} {...props} />
}
