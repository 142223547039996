import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer } from "./ClientprincipalContext";

export interface ISubscriptionItem {
  tenantid: string | undefined;
  isEnabled: boolean | undefined;
}

export interface IAssetItem {
  id: string;
  deviceName: string;
  autopilotEnrolled: boolean;
  deviceRegistrationState: string;
  deviceStatus: string;
  deviceType: string;
  enrolledDateTime: string;
  firstEnrolledDateTime: string;
  lastAssignedUser: string;
  locationId: string;
  notes: string;
  manufacturer: string;
  model: string;
  operatingSystem: string;
  productName: string;
  serialNumber: string;
  userDisplayName: string;
  userPrincipalName: string;
  warrantyStatus: string;
  assignedTo: string;
  assignedBy: string;
  assigmentDate: string;
}

export interface ILocationItem {
  id: string;
  locationName: string;
}

export interface ILocationItemAdd {
  locationName: string;
}

export interface ILocationItemDelete {
  id: string;
}

export type AssetContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: ISubscriptionItem | undefined;
  assets: IAssetItem[];
  locations: ILocationItem[];
  isLoadingAssets: boolean;
  isLoadingLocations: boolean;
  refreshSubscription: () => void;
  patchSubscription: (props: Partial<ISubscriptionItem>) => void;
  refreshAssets: () => void;
  patchAsset: (props: IAssetItem) => void;
  refreshLocations: () => void;
  patchLocation: (props: ILocationItem) => void;
  addLocation: (props: Partial<ILocationItem>) => void;
  deleteLocation: (props: Partial<ILocationItem>) => void;
};

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

const AssetDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  assets: [],
  locations: [],
  isLoadingAssets: false,
  isLoadingLocations: false,
  refreshSubscription: () => {},
  patchSubscription: (props: Partial<ISubscriptionItem>) => {},
  refreshAssets: () => {},
  patchAsset: (props: IAssetItem) => {},
  refreshLocations: () => {},
  patchLocation: (props: ILocationItem) => {},
  addLocation: (props: Partial<ILocationItem>) => {},
  deleteLocation: (props: Partial<ILocationItem>) => {},
};

export interface IProviderProps {
children?: any;
}

const AssetContext = React.createContext<AssetContextType>(AssetDefaultValue)

export const useAssetContext = () => {
  const context = React.useContext(AssetContext);
  if (!context) {
    throw new Error(
      `useAssetContext must be used within a AssetProvider`
    )
  }
  return context;
}

export const AssetProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<ISubscriptionItem | undefined>(undefined);
  const [assets, setAssets] = React.useState<IAssetItem[]>([]);
  const [locations, setLocations] = React.useState<ILocationItem[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = React.useState<boolean>(false)
  const [isLoadingLocations, setIsLoadingLocations] = React.useState<boolean>(false)
  const {clientprincipal, serviceOffers} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);

  const refreshSubscription = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && typeof clientprincipal?.tenantId === 'string'){
      //setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/asset/subscription', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISubscriptionItemValue) : (undefined))))
      .then((result) => {setSubscription(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        //setIsLoading(false);
      })
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);
  
  const patchSubscription = React.useCallback((props: Partial<ISubscriptionItem>) => {
    console.log('patchSubscription')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(subscription)
      console.log('isEnabled: ' + props.isEnabled)
      // Update Subscription
      console.log('Updating subscription')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          isEnabled: props.isEnabled,
        }),
      };
      fetch('/api/asset/subscription', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshSubscription()
      ) : (
        console.log('Failed to set subscription.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshSubscription()
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, subscription, refreshSubscription])

  const refreshAssets = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      setIsLoadingAssets(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/asset/asset', requestOptions)
      .then((response) => response.json())
      .then((result) => setAssets(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingAssets(false);
      })
    } else {
      setAssets([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, subscription?.isEnabled]);

  const patchAsset = React.useCallback((props: IAssetItem) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && clientprincipal.tenantId && subscription?.isEnabled){
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify({
          id: props.id,
          deviceStatus: props.deviceStatus,
          locationId: props.locationId,
          notes: props.notes,
        }),
      };
      fetch('/api/asset/asset', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshAssets();
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, subscription?.isEnabled, refreshAssets])

  const refreshLocations = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      setIsLoadingLocations(true);
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => response.json())
      .then((result) => setLocations(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoadingLocations(false);
      })
    } else {
      setLocations([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId, subscription?.isEnabled]);

  const patchLocation =  React.useCallback((props: ILocationItem) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && clientprincipal.tenantId && subscription?.isEnabled){
      const requestOptions = {
        method: 'PUT',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify(props),
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshLocations();
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled, refreshLocations])

  const addLocation = React.useCallback((props: Partial<ILocationItem>) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && clientprincipal.tenantId && subscription?.isEnabled){
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify(props),
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshLocations();
      })

    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled, refreshLocations])

  const deleteLocation = React.useCallback((props: Partial<ILocationItem>) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('asset') && clientprincipal.tenantId && subscription?.isEnabled){
      const requestOptions = {
        method: 'DELETE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                  },
        body: JSON.stringify(props),
      };
      fetch('/api/asset/location', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        refreshLocations();
      })

    } 
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled, refreshLocations])

  // Init asset array
  React.useEffect(() => {
    refreshAssets();
  },[clientprincipal, refreshAssets]);

  // init locations array
  React.useEffect(() => {
    refreshLocations();
  },[clientprincipal, refreshLocations]);

  // Init subscription object
  React.useEffect(() => {
    refreshSubscription();
  },[clientprincipal, refreshSubscription]);

  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "asset";
      }))
  },[serviceOffers, setServiceOffer]);

  const value = React.useMemo(() => ({serviceOffer, subscription, assets, locations, isLoadingAssets, isLoadingLocations, refreshSubscription, patchSubscription, refreshAssets, patchAsset, refreshLocations, patchLocation, addLocation, deleteLocation}), [serviceOffer, subscription, assets, locations, isLoadingAssets, isLoadingLocations, refreshSubscription, patchSubscription, refreshAssets, patchAsset, refreshLocations, patchLocation, addLocation, deleteLocation])
  return <AssetContext.Provider value={value} {...props} />
}


