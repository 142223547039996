import * as React from "react";
import {
  MenuList,
  MenuItem,
  Button,
  Menu,
  MenuTrigger,
  MenuPopover,
  Avatar,
  //PresenceBadge,
  //BadgeProps,
} from "@fluentui/react-components";
import { useNavigate } from 'react-router-dom';
import {
  PersonRegular,
  PersonAddRegular,
} from "@fluentui/react-icons";
import { useClientprincipal } from '../../Context/ClientprincipalContext';

const UserAvatar = () => {
  const {clientprincipal} = useClientprincipal();
  const [userPhoto, setUserPhoto] = React.useState<string | null>(null);

  async function fetchUserPhoto(tenantId:string, userId: string) {
    try {
        const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': tenantId,
                    'userId': userId},
        };
        const response = await fetch('/api/user/photo', requestOptions)
        const payload = await response.json()
        const photo = payload.photo
        return photo
    } catch (error) {
        console.error('No respoonse could be returned')
        return undefined
    }
  }

  React.useEffect(() => {
    if (clientprincipal?.isAuthenticated &&
      typeof clientprincipal?.tenantId === 'string' &&
      typeof clientprincipal?.userId === 'string') {

      const tenantid = clientprincipal.tenantId || '';
      const userid = clientprincipal.userId || '';

      ;(async () => {
        const photoResponse = await fetchUserPhoto(tenantid, userid);
        if (!photoResponse) {
          setUserPhoto(null)
        } else {
          setUserPhoto(photoResponse)
        }
      })()

    } else {
      setUserPhoto(null);
    }
  },[clientprincipal]);

  const UserAvatar = (() => {
    if (clientprincipal?.isAuthenticated) {
      return (
        <Avatar 
          icon={(!userPhoto) ? (<PersonRegular />) : (<img src={`data:image/jpeg;base64,${userPhoto}`} alt="of user" />)}
          name={""} size={48} badge={{status: 'available'}} 
          aria-label="Avatar"
        />
      )
    } else {
      return (
        <Avatar icon={<PersonAddRegular />} name={""} size={48} badge={{status: 'offline'}} aria-label="Avatar" />
      )
    }
  });

  return (
      <>
      <tr>
        <td>
          {(clientprincipal?.isAuthenticated) && (clientprincipal?.displayName)}<br/>
          {(clientprincipal?.isAuthenticated) && (clientprincipal?.userName)}
        </td>
        <td>
          <UserAvatar />
        </td>
      </tr>
      </>
  );
}

export const Default = () => {
  const navigate = useNavigate();
  //const [clientprincipal, DisposeClientprincipal] = useClientprincipal()
  const {clientprincipal, DisposeClientprincipal} = useClientprincipal();
  // onClick={window.location.href = "www.google.com"}
  return (
  <Menu>
    <MenuTrigger disableButtonEnhancement>
      <Button shape="square" appearance="subtle"><UserAvatar/></Button>
    </MenuTrigger>

    <MenuPopover>
    <MenuList>
        { !clientprincipal?.isAuthenticated ? (
          <MenuItem onClick={() => {window.location.pathname = "/login"}}>
            Login
          </MenuItem>
        ):(
          <>
          <MenuItem onClick={() => navigate("/profile")}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => {
              DisposeClientprincipal()
              window.location.pathname = "/logout";
              /*
                setClientPrincipal({
                  clientPrincipal:    null,
                  isAuthenticated:    false,
                  userName:           null,
                  displayName:        null,
                  userId:             null,
                  tenantId:           null,
                  roles:              [],
                  claims:             [],
                  photo:              null,
                });
              */
              }
            }>
            Logout
          </MenuItem>
          </>
          )
        }
      </MenuList>
    </MenuPopover>
  </Menu>
);
}