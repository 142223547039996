import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Caption1,
} from "@fluentui/react-components";
import { 
    Switch,
    SwitchProps,
  } from "@fluentui/react-components";
  import { 
    Edit24Regular,
  } from "@fluentui/react-icons";
import {useServiceContext, IServiceManagementItem, ISigninLogItem} from '../../Context/ServiceContext'

export const OpenService = (props: IServiceManagementItem) => {
    //const [isOpen, setIsOpen] = React.useState(false);
    const [enabled, setEnabled] = React.useState<boolean>(props['isEnabled']);
    const {updateService} = useServiceContext();

    //const applyStyles = useApplyButtonStyles();

    const onClickApplyButton = React.useCallback(() => {
      if ( props['isEnabled'] !== enabled ) {
        // Update Table
        const inputObject = {
            id: props['id'],
            isEnabled: enabled,
            //tenantid: clientprincipal?.tenantId,
            name: props['name'],
        }
        //alert("Settings changed service status must be updated.")
        updateService(inputObject);
      }
      
      // alert("alert! isGeneral:"+props['isGeneral']+" general:"+general)
      //setIsOpen(false);
    }, [props, enabled, updateService]);

    const Enabled = (props: SwitchProps) => (
      <Switch label={"Available to administrators"} checked={enabled} onChange={() => setEnabled(!enabled)} {...props} />
    );

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<Edit24Regular />} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {props.displayname}
            <br></br>
            <Caption1>
                {`${props?.retailprice} ${props?.currencycode} per ${props?.unittype}`}
            </Caption1>
          </DialogTitle>
          <DialogContent>
              {props.description}
          </DialogContent>
          <DialogActions position="start">
            <Enabled />
          </DialogActions>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button shape="square" appearance="outline">Close</Button>
            </DialogTrigger>
                {
                    ( props['isEnabled'] !== enabled ) ? (
                        <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
                    ) :(
                        <Button shape="square" appearance="outline" disabled >Apply</Button>
                    )
                }
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export const OpenSigninlog = (props: ISigninLogItem) => {
return (
  <Dialog>
    <DialogTrigger disableButtonEnhancement>
        <Button appearance="transparent" icon={<Edit24Regular />} />
    </DialogTrigger>
    <DialogSurface>
      <DialogBody>
        <DialogTitle>
          {props.userprincipalName}
          <br></br>
          <Caption1>
              Sign-in log details
          </Caption1>
        </DialogTitle>
        <DialogContent>
        <div role="tabpanel" aria-labelledby="item">
            <table>
              <tr><th>Userprincipalname</th><td>{props.userprincipalName}</td></tr>
              <tr><th>Username</th><td>{props.userName}</td></tr>
              <tr><th>Timestamp</th><td>{props.logTimestamp}</td></tr>
              <tr><th>Methods</th><td>{props.amr}</td></tr>
              <tr><th>Tenant Id</th><td>{props.tenantId}</td></tr>
              <tr><th>User Id</th><td>{props.userId}</td></tr>
              <tr><th>Roles</th><td>{props.roles}</td></tr>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button shape="square" appearance="outline">Close</Button>
          </DialogTrigger>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
);
};