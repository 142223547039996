import React from 'react';
import { Navigation } from './Components/Navigation';
import { teamsLightTheme, teamsDarkTheme, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import { FluentProvider } from '@fluentui/react-components';
import { ThemeProvider, useTheme } from './Context/ThemeContext'
import {default as MainComponent} from './Components/MainComponent'
import {default as HeaderPane} from './Components/HeaderPane'
import {default as HeaderNavPane} from './Components/HeaderNavPane'

// Apply Theme
const DisplayWithTheme = () => {
  const {theme, setTheme} = useTheme()

  return (
    <FluentProvider theme={theme.theme}>
      <Layout />
    </FluentProvider>
  )
}

// Define Layout
const Layout = () => (
  <div className="App-container">
    <header>
      <HeaderPane />
    </header>
    <navheader>
      <HeaderNavPane />
    </navheader>
    <nav>
      <Navigation />
    </nav>
    <main>
      <MainComponent />
    </main>
  </div>
)
  

const App = () => (
  <ThemeProvider>
      <DisplayWithTheme />
  </ThemeProvider>
);

export default App;