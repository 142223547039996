import * as React from "react";
import {
  Tab,
  TabList,
  SpinnerProps,
  Spinner,
} from "@fluentui/react-components";
import {
  Apps24Filled,
  Apps24Regular,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  DatabaseSearch24Filled,
  DatabaseSearch24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as Faq } from './Faq'
import { default as ServiceManagement } from './ServiceManagement'
import { default as SigninLogs } from './SigninLogs'

const FaqIcons = bundleIcon(DocumentOnePageMultiple24Filled, DocumentOnePageMultiple24Regular);
const AppsIcons = bundleIcon(Apps24Filled, Apps24Regular);
const LogsIcons = bundleIcon(DatabaseSearch24Filled, DatabaseSearch24Regular);

export const DefaultSpinner = (props: Partial<SpinnerProps>) => <Spinner {...props} />;

export const TabsWithPanels = () => {
  const [selectedValue, setSelectedValue] = React.useState<TabValue>('default')
  
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const FaqPanel = React.memo(() => (
      <Faq />
  ));

  const ServiceManagementPanel = React.memo(() => (
      <ServiceManagement />
  ));

  const AuthlogPanel = React.memo(() => (
    <SigninLogs />
));

  const ActiveTabs = React.useCallback(() => {
    return (
      <>
        <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
          <Tab id="FAQ" icon={<FaqIcons />} value="faq">
            FAQ
          </Tab>
          <Tab id="Services" icon={<AppsIcons />} value="services">
            Services
          </Tab>
          <Tab id="Services" icon={<LogsIcons />} value="authlog">
            Sign-In logs
          </Tab>
        </TabList>
        <div className="App-contentgrid">
          {selectedValue === "default" && <ServiceManagementPanel />}
          {selectedValue === "faq" && <FaqPanel />}
          {selectedValue === "services" && <ServiceManagementPanel />}
          {selectedValue === "authlog" && <AuthlogPanel />}
        </div>
      </>
    )
  },[selectedValue, ServiceManagementPanel, AuthlogPanel, FaqPanel])

  return (
    <ActiveTabs />
  );
};

export default TabsWithPanels;