import * as React from "react";

import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
} from "@fluentui/react-components";
import { useTheme, themesAvaliable } from '../../Context/ThemeContext'

export const Default = () => {
  const {theme, setTheme} = useTheme()

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button shape="square" appearance="subtle">Theme: {theme.themeName}</Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {themesAvaliable
            .map(o => (
                <MenuItem onClick={() => setTheme(o)} >
                {o.themeName}
                </MenuItem>
            ))
          }
        </MenuList>
      </MenuPopover>
    </Menu>
  );

}
