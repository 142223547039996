import * as React from "react";
import { ServiceCard } from "./ServiceCard";

export const Settings = () => {
  return (
    <div>
      <ServiceCard />
    </div>
  )
};

export default Settings;

/*
import * as React from "react";
import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { 
  Save24Regular,
  ArrowSync24Regular,
} from "@fluentui/react-icons";
import {
  Switch,
  SwitchProps,
  //Spinner,
} from "@fluentui/react-components";
import { useMSCSPContext } from "../../Context/MSCSPContext";
import type { ISubscriptionItem } from "../../Context/MSCSPContext";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});



export const Settings = () => {
  const {subscription, patchSubscription} = useMSCSPContext();
  const styles = useStyles();
  const [subscriptionSettings, setSubscriptionSettings] = React.useState<ISubscriptionItem>(subscription || {tenantid: undefined, isEnabled: false});


  const onClickSaveButton = React.useCallback(() => {
    patchSubscription(
      {
        //tenantid:undefined,
        isEnabled: subscriptionSettings.isEnabled
      }
    )
  }, [subscriptionSettings, patchSubscription]);

  const SaveButton = React.useCallback(() => {
    return (
        (subscription && subscriptionSettings.isEnabled !== subscription?.isEnabled) ? (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton}>Save</Button>
        ) : (
          <></>
        )

    )
  },[subscriptionSettings, subscription, onClickSaveButton])

  const SubscriptionEnabled = React.useCallback((props: SwitchProps) => (
    <Switch 
      label={subscriptionSettings.isEnabled ? ("Enabled") : ("Disabled")}
      checked={subscriptionSettings.isEnabled}
      onChange={() => setSubscriptionSettings({
        ...subscriptionSettings,
        isEnabled: !(subscriptionSettings.isEnabled)
      })}
      {...props}
    />
  ),[subscriptionSettings]);
  
  return (
    <div>
      <br />
      <table className={styles.propsTable}>
        <tbody>
          <tr>
            <td>MSCSP Management</td>
          </tr>
          <tr>
            <td><SubscriptionEnabled /></td>
            <td><SaveButton /></td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  )
};

export default Settings;
*/