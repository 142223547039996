import * as React from "react";
import {
  ArrowSync24Regular,
} from "@fluentui/react-icons";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  DataGridProps,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Spinner,
} from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Button, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Checkbox } from "@fluentui/react-components";
import { TextColumnThree24Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import { Field } from "@fluentui/react-components";
import { 
  useApplicationContext as useContext,
  ILogItem as IGridItem,
} from "../../Context/ApplicationContext";
import {OpenLogItem} from './Drawer';
import {SortDate, SortString} from "../../Functions/sorting";

/*
function SortDate (a:string, b:string) {
    if (typeof a === 'string' && typeof b === 'string') {
      if (!isNaN(Date.parse(a)) || !isNaN(Date.parse(b))) {
        return Date.parse(a).valueOf() - Date.parse(b).valueOf()
      } else if (!isNaN(Date.parse(a)) && isNaN(Date.parse(b))) {
        // a is a date but not b
        return 1;
      } else if (isNaN(Date.parse(a)) && !isNaN(Date.parse(b))) {
        // b is a date but not a
        return -1;
      } else {
        return a.localeCompare(b)
      }
    } else {
      if (a === b) {
        return 0;
      } else if ((a === undefined) || (a === null && b !== undefined)) { 
        // null and undefined should always be swapped to become at the beginning, but undefined should be before null
        return -1;
      } else if (b === undefined || b === null) { 
        return 1; 
      } else return (a < b ? -1 : 1)
    }
}

function SortString(a:string, b:string) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  } else {
    if (a === b) {
      return 0;
    } else if ((a === undefined) || (a === null && b !== undefined)) {
      return -1;
    } else if (b === undefined || b === null) { 
      return 1; 
    } else return (a < b ? -1 : 1)
  }
}
*/
const defaultColumns = ['actions','DisplayName','DisplayVersion','ApplicationType','StartTime','EndTime','Duration','State','Status','DetailedStatus']
const searchPropertyName = 'DisplayName'

const columns: TableColumnDefinition<IGridItem>[] = [
  createTableColumn<IGridItem>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
        <OpenLogItem {...item} />
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "StartTime",
    compare: (a, b) => {
        return SortDate(a.StartTime,b.StartTime);

      /*
        if (typeof a.StartTime === 'string' && typeof b.StartTime === 'string') {
          if (!isNaN(Date.parse(a.StartTime)) || !isNaN(Date.parse(b.StartTime))) {
            return Date.parse(a.StartTime).valueOf() - Date.parse(b.StartTime).valueOf()
          } else if (!isNaN(Date.parse(a.StartTime)) && isNaN(Date.parse(b.StartTime))) {
            // a is a date but not b
            return 1;
          } else if (isNaN(Date.parse(a.StartTime)) && !isNaN(Date.parse(b.StartTime))) {
            // b is a date but not a
            return -1;
          } else {
            return a.StartTime.localeCompare(b.StartTime)
          }
        } else {
          if (a.StartTime === b.StartTime) {
            return 0;
          } else if ((a.StartTime === undefined) || (a.StartTime === null && b.StartTime !== undefined)) { 
            // null and undefined should always be swapped to become at the beginning, but undefined should be before null
            return -1;
          } else if (b.StartTime === undefined || b.StartTime === null) { 
            return 1; 
          } else return (a.StartTime < b.StartTime ? -1 : 1)
        }
      */
    },
    renderHeaderCell: () => {
      return "Start Time";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.StartTime}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "EndTime",
    compare: (a, b) => {
      return SortDate(a.EndTime,b.EndTime);

      /*
        if (typeof a.EndTime === 'string' && typeof b.EndTime === 'string') {
          if (!isNaN(Date.parse(a.EndTime)) || !isNaN(Date.parse(b.EndTime))) {
            return Date.parse(a.EndTime).valueOf() - Date.parse(b.EndTime).valueOf()
          } else if (!isNaN(Date.parse(a.EndTime)) && isNaN(Date.parse(b.EndTime))) {
            // a is a date but not b
            return 1;
          } else if (isNaN(Date.parse(a.EndTime)) && !isNaN(Date.parse(b.EndTime))) {
            // b is a date but not a
            return -1;
          } else {
            return a.EndTime.localeCompare(b.EndTime)
          }
        } else {
          if (a === b) {
            return 0;
          } else if ((a.EndTime === undefined) || (a.EndTime === null && b.EndTime !== undefined)) { 
            // null and undefined should always be swapped to become at the beginning, but undefined should be before null
            return -1;
          } else if (b.EndTime === undefined || b.EndTime === null) { 
            return 1; 
          } else return (a.EndTime < b.EndTime ? -1 : 1)
        }
      */
    },
    renderHeaderCell: () => {
      return "End Time";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {
            item.EndTime
            //(item.EndTime === undefined) ? ('undefined ' + isNaN(Date.parse(item.EndTime))) : ((item.EndTime === null) ? ( 'null ' + isNaN(Date.parse(item.EndTime))) : (Date.parse(item.EndTime)) +  ' ' + isNaN(Date.parse(item.EndTime)))
          }
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "Duration",
    compare: (a, b) => {
      return SortDate(a.Duration,b.Duration);
      /*
      if (typeof a.Duration === 'string' && typeof b.Duration === 'string') {
        if (!isNaN(Date.parse(a.Duration)) || !isNaN(Date.parse(b.Duration))) {
          return Date.parse(a.Duration).valueOf() - Date.parse(b.Duration).valueOf()
        } else if (!isNaN(Date.parse(a.Duration)) && isNaN(Date.parse(b.Duration))) {
          // a is a date but not b
          return 1;
        } else if (isNaN(Date.parse(a.Duration)) && !isNaN(Date.parse(b.Duration))) {
          // b is a date but not a
          return -1;
        } else {
          return a.Duration.localeCompare(b.Duration)
        }
      } else {
        if (a.Duration === b.Duration) {
          return 0;
        } else if ((a.Duration === undefined) || (a.Duration === null && b.Duration !== undefined)) { 
          // null and undefined should always be swapped to become at the beginning, but undefined should be before null
          return -1;
        } else if (b.Duration === undefined || b.Duration === null) { 
          return 1; 
        } else return (a.Duration < b.Duration ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Duration";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.Duration}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DisplayName",
    compare: (a, b) => {
      return SortString(a.DisplayName, b.DisplayName)
      /*
      if (typeof a.DisplayName === 'string' && typeof b.DisplayName === 'string') {
        return a.DisplayName.localeCompare(b.DisplayName)
      } else {
        if (a.DisplayName === b.DisplayName) {
          return 0;
        } else if ((a.DisplayName === undefined) || (a.DisplayName === null && b.DisplayName !== undefined)) {
          return -1;
        } else if (b.DisplayName === undefined || b.DisplayName === null) { 
          return 1; 
        } else return (a.DisplayName < b.DisplayName ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Display Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DisplayName}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DisplayVersion",
    compare: (a, b) => {
      return SortString(a.DisplayVersion, b.DisplayVersion)

      /*
      if (typeof a.DisplayVersion === 'string' && typeof b.DisplayVersion === 'string') {
        return a.DisplayVersion.localeCompare(b.DisplayVersion)
      } else {
        if (a.DisplayVersion === b.DisplayVersion) {
          return 0;
        } else if ((a.DisplayVersion === undefined) || (a.DisplayVersion === null && b.DisplayVersion !== undefined)) {
          return -1;
        } else if (b.DisplayVersion === undefined || b.DisplayVersion === null) { 
          return 1; 
        } else return (a.DisplayVersion < b.DisplayVersion ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Display Version";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DisplayVersion}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "ApplicationType",
    compare: (a, b) => {
      return SortString(a.ApplicationType, b.ApplicationType)

      /*
      if (typeof a.ApplicationType === 'string' && typeof b.ApplicationType === 'string') {
        return a.ApplicationType.localeCompare(b.ApplicationType)
      } else {
        if (a.ApplicationType === b.ApplicationType) {
          return 0;
        } else if ((a.ApplicationType === undefined) || (a.ApplicationType === null && b.ApplicationType !== undefined)) {
          return -1;
        } else if (b.ApplicationType === undefined || b.ApplicationType === null) { 
          return 1; 
        } else return (a.ApplicationType < b.ApplicationType ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Application Type";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.ApplicationType}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "Status",
    compare: (a, b) => {
      return SortString(a.Status, b.Status)

      /*
      if (typeof a.Status === 'string' && typeof b.Status === 'string') {
        return a.Status.localeCompare(b.Status)
      } else {
        if (a.Status === b.Status) {
          return 0;
        } else if ((a.Status === undefined) || (a.Status === null && b.Status !== undefined)) {
          return -1;
        } else if (b.Status === undefined || b.Status === null) { 
          return 1; 
        } else return (a.Status < b.Status ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Status";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.Status}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "State",
    compare: (a, b) => {
      return SortString(a.State, b.State)

      /*
      if (typeof a.State === 'string' && typeof b.State === 'string') {
        return a.State.localeCompare(b.State)
      } else {
        if (a.State === b.State) {
          return 0;
        } else if ((a.State === undefined) || (a.State === null && b.State !== undefined)) {
          return -1;
        } else if (b.State === undefined || b.State === null) { 
          return 1; 
        } else return (a.State < b.State ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "State";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.State}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<IGridItem>({
    columnId: "DetailedStatus",
    compare: (a, b) => {
      return SortString(a.DetailedStatus, b.DetailedStatus)

      /*
      if (typeof a.DetailedStatus === 'string' && typeof b.DetailedStatus === 'string') {
        return a.DetailedStatus.localeCompare(b.DetailedStatus)
      } else {
        if (a.DetailedStatus === b.DetailedStatus) {
          return 0;
        } else if ((a.DetailedStatus === undefined) || (a.DetailedStatus === null && b.DetailedStatus !== undefined)) {
          return -1;
        } else if (b.DetailedStatus === undefined || b.DetailedStatus === null) { 
          return 1; 
        } else return (a.DetailedStatus < b.DetailedStatus ? -1 : 1)
      }
      */
    },
    renderHeaderCell: () => {
      return "Detailed Status";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.DetailedStatus}
        </TableCellLayout>
      );
    },
  }),
];

const columnSizingOptions = {
  actions: {
    minWidth: 40,
    defaultWidth: 40,
  },
  DisplayName: {
    minWidth: 100,
    defaultWidth: 200,
  },
  DisplayVersion: {
    minWidth: 150,
    defaultWidth: 400,
  },
  ApplicationType: {
    minWidth: 150,
    defaultWidth: 400,
  },
  StartTime: {
    defaultWidth: 200,
    minWidth: 120,
    idealWidth: 200,
  },
  EndTime: {
    defaultWidth: 200,
    minWidth: 120,
    idealWidth: 200,
  },
  Duration: {
    defaultWidth: 200,
    minWidth: 120,
    idealWidth: 200,
  },
  State: {
    minWidth: 150,
    defaultWidth: 400,
  },
  Status: {
    minWidth: 150,
    defaultWidth: 200,
  },
  DetailedStatus: {
    minWidth: 150,
    defaultWidth: 200,
  },
};

const useDataGridStyles = makeStyles({
  mmDataGrid: {
    "> div": { 
       display: "grid",
       overflowX: "clip",
       overflowY: "auto",
      },
    display: "grid",
    overflowX: "clip",
    overflowY: "auto",
  },
});

const useDrawerStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
  },
});

const useApplyButtonStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "left",
    position: "absolute",
    marginBottom: '1em',
    bottom: 0,
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const ResizableColumns = () => {
  const styles = useDataGridStyles();
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const {logItems, refreshLogItems, isLogLoading} = useContext();
  const [filteredItems, setFilteredItems] = React.useState<IGridItem[]>([])
  const [showColumns, setShowColumns] = React.useState<TableColumnDefinition<IGridItem>[]>(
    columns.filter(o => (
      defaultColumns
      .includes(o.columnId.toString())
    )))
  const [searchFilter, setSearchFilter] = React.useState("");
  const [validSearchFilter, setValidSearchFilter] = React.useState(true);

  const onSearchBoxChange: SearchBoxProps["onChange"] = (ev, data) => {
    if (data.value.length <= 20) {
      setSearchFilter(data.value);
      setValidSearchFilter(true);
    } else {
      setValidSearchFilter(false);
    }
  };

  // Refresh LogItems (isLogLoading)
  const RefreshButton = () => {
    const styles = useDrawerStyles();

    return (
      <div className={styles.content}>
        {
          isLogLoading ? (
            <Button
              shape="square"
              appearance="subtle"
              aria-label="Refresh"
              icon={<ArrowSync24Regular className="Spinner-icon" />}
              size="medium"
            >Refresh</Button>
          ) : (
            <Button
                shape="square"
                appearance="subtle"
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={refreshLogItems}
                size="medium"
              >Refresh</Button>
          )
        }
      </div>
    )
  }

  // Set showColumns
  React.useEffect(() => {
    setShowColumns(showColumns)
  },[showColumns])

  // Set searchFilter (DisplayName)
  React.useEffect(() => {
    if(searchFilter !== "") {
      setFilteredItems(
        logItems.filter(item => 
          (
            (item[searchPropertyName]?.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) && (item[searchPropertyName] !== null)
          )
        )
      )
    } else {
      setFilteredItems(logItems)
    }
  },[searchFilter, logItems])

  // Columns Selector Drawer
  const ColumnsDrawer = () => {
    interface IColumn {
        name: string;
        enabled: boolean;
        column: TableColumnDefinition<IGridItem>;
    }
    const styles = useDrawerStyles();
    const applyStyles = useApplyButtonStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [columnsRef, setColumnsRef] = React.useState<IColumn[]>(
      columns
      .map(o => ({
        name: o.columnId.toString(),
        enabled: showColumns.some(c => c.columnId === o.columnId),          
        column: o
      })) as IColumn[]
    )
    
    /*
    function handleCheckBox(index:number) {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    }
    */

    const handleCheckBox = (index:number) => {
      const nextCounters = columnsRef.map((o, i) => {
        if (i === index) {
          // Update checkbox (enabled)
          return {
            name: o.name,
            enabled: !(o.enabled),
            column: o.column
          };
        } else {
          // The rest haven't changed
          return o;
        }
      });
      setColumnsRef(nextCounters);
    }
    
    const onClickApplyButton = React.useCallback(() => {
      setIsOpen(false);
      setShowColumns(columnsRef
        .filter(o => (o.enabled))
        .map(o => (o.column)));
    }, [columnsRef]);

    const onClickColumnsButton = React.useCallback(() => {
      setIsOpen(true);
    }, []);

    return (
      <div>
        <DrawerOverlay
          position="right"
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {setIsOpen(false)}}
                />
              }
            >
              Select Columns
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            <Checkbox
              checked={
                  columnsRef.every( o => o.enabled === true ) ? true : columnsRef.every( o => o.enabled === false ) ? false : "mixed"
              }
              onChange={(_ev, data) => {
                const nextColumnsRef = columnsRef
                    .map((o) => {
                      return {
                        name: o.name,
                        enabled: !!data.checked,
                        column: o.column
                      }
                    });

                setColumnsRef(
                  nextColumnsRef
                )
              }}
              label="All columns"
            />
            <br></br>
            {
              columnsRef.map((o, index) =>
                <div>
                <Checkbox
                  key={index}
                  checked={o.enabled}
                  onChange={() => handleCheckBox(index)}
                  label={o.name}
                /><br></br>
                </div>
              )
            }
            <div className={applyStyles.content}>
              <Button shape="square" appearance="outline" onClick={onClickApplyButton}>Apply</Button>
            </div>
          </DrawerBody>
        </DrawerOverlay>
        <div className={styles.content}>
          <Button shape="square" appearance="subtle" icon={<TextColumnThree24Regular />} onClick={onClickColumnsButton}>
            Columns
          </Button>
        </div>
      </div>
    );
  };

  const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>(
    () => ({ sortColumn: "StartTime", sortDirection: "descending"}), []
  );

  // GridArea (isLogLoading)
  const GridArea = React.useCallback(() => {
    return (
      <div className='App-tablearea'>  
        <DataGrid
          items={filteredItems}
          columns={showColumns}
          sortable
          defaultSortState={defaultSortState}
          noNativeElements
          getRowId={(item) => item.Timestamp}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          className={styles.mmDataGrid}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                            columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          {
            (!isLogLoading) ? (
              <div className='App-tablearea'>
                <DataGridBody<IGridItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<IGridItem>
                      key={rowId}
                    >
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </div>
            ) : (
              <Spinner label="Loading..." labelPosition="below"/>
            )
          }
        </DataGrid>
      </div>
    )
  },[filteredItems,showColumns,isLogLoading,styles.mmDataGrid, defaultSortState])

  return (
    <>
      <div className='App-toparea'>
        <Field
          validationState={validSearchFilter ? "success" : "warning"}
          validationMessage={validSearchFilter ? "("+filteredItems.length+"/"+logItems.length+")" : "Input is limited to 20 characters."}
        >
          <tr>
            <td><SearchBox value={searchFilter} onChange={onSearchBoxChange} placeholder="Search" /></td>
            <td><RefreshButton /></td>
            <td><ColumnsDrawer /></td>
          </tr>
        </Field>
      </div>
      <GridArea />
    </>
  );
};

export default ResizableColumns;