import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  //Caption1,
  Divider,
} from "@fluentui/react-components";
import { 
    Edit24Regular,
  } from "@fluentui/react-icons";
import {
  IInvoiceItem,
  ILineItem,
} from '../../Context/BillingContext'
import {
  useClientprincipal
} from '../../Context/ClientprincipalContext'
import type {
  IServiceOffer
} from '../../Context/ClientprincipalContext'

export const OpenService = (props: IInvoiceItem) => {
  const {serviceOffers} = useClientprincipal();

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<Edit24Regular />} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            Invoice {props.invoiceNumber}
            <Divider>Invoice details</Divider>
          </DialogTitle>
          <DialogContent>
          <div role="tabpanel" aria-labelledby="item">
            <table>
              <tr><th>Invoice Number</th><td>{props.invoiceNumber}</td></tr>
              <tr><th>Invoice Date</th><td>{(new Date(Date.parse(props.invoiceDate))).toLocaleDateString('sv-SE')}</td></tr>
              <tr><th>Total Charges</th><td>{props.totalCharges + ' ' + props.currencyCode}</td></tr>
            </table>
            <>
              {
                props.lineItems.map((item:ILineItem,index) => 
                  <>
                  <br></br>
                  <Divider>Lineitem #{index+1}</Divider>
                  <br></br>
                  <table>
                      <tr><th>Description</th><td>{
                          serviceOffers.find((element: IServiceOffer) => {
                            return element.id === item.serviceId;
                          })?.displayname
                        }</td></tr>
                      {
                        // <tr><th>ServiceId</th><td>{item.serviceId}</td></tr>
                      }
                      <tr><th>Billing Period</th><td>{item.billingPeriod}</td></tr>
                      <br></br>
                      <tr><th>Quantity</th><td>{item.quantity}</td></tr> 
                      <tr><th>Price</th><td>{item.retailPrice + ' ' + item.currencyCode}</td></tr>
                      <tr><th>Total charged</th><td>{item.totalPrice + ' ' + item.currencyCode}</td></tr>
                  </table>
                  </>
                )
              }
            </>
          </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button shape="square" appearance="outline">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};