import React from "react"
import { useClientprincipal } from "./ClientprincipalContext";
import type { IServiceOffer } from "./ClientprincipalContext";

export interface ISubscriptionItem {
  tenantid: string | undefined;
  isEnabled: boolean | undefined;
}

export interface IApplicationItem {
  // Application catalog from table: tblApps
  id: string;
  logoUri: string;
  largeIcon: string; //{value: string, type: string};
  displayName: string;
  vendor: string;
  description: string;
  generalVersion: string;
  previewVersion: string;
  updateVersion: string;
  isKeyRequired: boolean;       // licenseKey is required for application
  
  // Calculated in function
  isKeySet: boolean;            // licenseKey exist in tenant table (calculated if required)
  
  // selfService: boolean;    => removed only used by api not tenant.
  
  // Tenant Settings from tabl: {guid}Apps
  isGeneral: boolean;    // General channel is enabled
  isUpdate: boolean;     // Update channel is enabled
  isPreview: boolean;    // Preview channel is enabled
  isPublic:boolean;     // Standard application or Custom
}

export interface IWinGetApplicationItem {
  id: string;
  largeIcon: string; //{value: string, type: string};
  displayName: string;
  vendor: string;
  description: string;
}

export interface ILogItem {
  // Application catalog from table: tblApps
  id: string;
  ApplicationId: string;
  ApplicationType: string;
  DetailedStatus: string;
  DisplayName: string;
  DisplayVersion: string;
  Duration: string;
  EndTime: string;
  StartTime: string;
  State: string;
  Status: string;
  TenantId: string;
}

export const DefaultISubscriptionItemValue = {
  tenantid: undefined,
  isEnabled: undefined,
};

export type ApplicationContextType = {
  serviceOffer: IServiceOffer | undefined;
  subscription: ISubscriptionItem | undefined;
  items: IApplicationItem[];
  winGetAppItems: IWinGetApplicationItem[],
  isLoading: boolean;
  logItems: ILogItem[];
  isLogLoading: boolean;
  isWinGetAppItemsLoading: boolean;
  refreshSubscription: () => void;
  patchSubscription: (props: Partial<ISubscriptionItem>) => void;
  refreshItems: () => void;
  refreshWinGetAppItems: () => void;
  refreshLogItems: () => void;
  synchronizeApplication: (props: Partial<IApplicationItem>) => void;
  provisionWinGetApp: (props: Partial<IWinGetApplicationItem>) => void;
  setApplication: (props: Partial<IApplicationItem>) => void;
};

const ApplicationDefaultValue = {
  serviceOffer: undefined,
  subscription: undefined,
  items: [],
  winGetAppItems: [],
  isLoading: false,
  logItems: [],
  isLogLoading: false,
  isWinGetAppItemsLoading: false,
  refreshItems: () => {},
  refreshWinGetAppItems: () => {},
  refreshSubscription: () => {},
  refreshLogItems: () => {},
  patchSubscription: (props: Partial<ISubscriptionItem>) => {},
  synchronizeApplication: (props: Partial<IApplicationItem>) => {},
  provisionWinGetApp: (props: Partial<IWinGetApplicationItem>) => {},
  setApplication: (props: Partial<IApplicationItem>) => {},
};

export interface IProviderProps {
children?: any;
}

const ApplicationContext = React.createContext<ApplicationContextType>(ApplicationDefaultValue)

export const useApplicationContext = () => {
  const context = React.useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      `useApplicationContext must be used within a ApplicationProvider`
    )
  }
  return context;
}

export const ApplicationProvider = (props: IProviderProps) => {
  const [subscription, setSubscription] = React.useState<ISubscriptionItem | undefined>(undefined);
  const [items, setItems] = React.useState<IApplicationItem[]>([]);
  const [winGetAppItems, setWinGetAppItems] = React.useState<IWinGetApplicationItem[]>([]);
  const [logItems, setLogItems] = React.useState<ILogItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isWinGetAppItemsLoading, setIsWinGetAppItemsLoading] = React.useState<boolean>(false)
  const [isLogLoading, setIsLogLoading] = React.useState<boolean>(false)
  const {clientprincipal, serviceOffers} = useClientprincipal();
  const [serviceOffer, setServiceOffer] = React.useState<IServiceOffer | undefined>(undefined);
    
  const refreshSubscription = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string'){
      //setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                   },
      };
      fetch('/api/application/subscription', requestOptions)
      .then((response) => (
        (response.status === 200) ? (
          response.json()
        ) : ((response.status === 204) ? (DefaultISubscriptionItemValue) : (undefined))))
      .then((result) => {setSubscription(result); console.log(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        // setIsLoading(false);
      })
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId]);
  
  const patchSubscription = React.useCallback((props: Partial<ISubscriptionItem>) => {
    console.log('patchSubscription')
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      console.log(subscription)
      console.log('isEnabled: ' + props.isEnabled)
      // Update Subscription
      console.log('Updating subscription')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: clientprincipal.tenantId,
          isEnabled: props.isEnabled,
        }),
      };
      fetch('/api/application/subscription', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshSubscription()
      ) : (
        console.log('Failed to set subscription.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        refreshSubscription()
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, subscription, refreshSubscription])

  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      setIsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
          },
      };
      fetch('/api/application/catalog', requestOptions)
      .then((response) => response.json())
      .then((result) => setItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
    } else {
      setItems([])
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled]);

  const synchronizeApplication = React.useCallback((props: Partial<IApplicationItem>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      console.log(`Syncronizing: ${props.id}`)
      console.log(props)
      // Syncronize Application (General)
      if (props.isGeneral) {
        console.log(`Syncronizing: ${props.id} (General)`)
        const requestOptions = {
          method: 'POST',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                    },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "General",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }

      // Syncronize Application (Update)
      if (props.isUpdate) {
        console.log(`Syncronizing: ${props.id} (Update)`)
        const requestOptions = {
          method: 'POST',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                    },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "Update",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }

      // Syncronize Application (Preview)
      if (props.isPreview) {
        console.log(`Syncronizing: ${props.id} (Preview)`)
        const requestOptions = {
          method: 'POST',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                    },
          body: JSON.stringify({
            "x-mm-tenant-id": clientprincipal.tenantId,
            "x-mm-application-id": props.id,
            "x-mm-application-type": "Preview",
          }),
        };
        fetch('/api/application/synchronize', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
      }
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled])

  const setApplication = React.useCallback((props: Partial<IApplicationItem>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      // Set Application
        console.log(`Set: ${props.id} (General)`)
        const requestOptions = {
          method: 'MERGE',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
                    },
          body: JSON.stringify({
            id: props.id,
            isGeneral: props.isGeneral,
            isUpdate: props.isUpdate,
            isPreview: props.isPreview,
          }),
        };
        fetch('/api/application/catalog', requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log("An error occured"))
        .finally(() => {
        })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled])

  const refreshWinGetAppItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      setIsWinGetAppItemsLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
          },
      };
      fetch('/api/application/wingetapp/catalog', requestOptions)
      .then((response) => response.json())
      .then((result) => setWinGetAppItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsWinGetAppItemsLoading(false);
      })
    } else {
      setItems([])
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled]);

  const provisionWinGetApp = React.useCallback((props: Partial<IWinGetApplicationItem>) => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      console.log(`Provisioning: ${props.id}`)
      console.log(props)
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId
                  },
        body: JSON.stringify({
          "x-mm-tenant-id": clientprincipal.tenantId,
          "x-mm-application-id": props.id
        }),
      };
      fetch('/api/application/wingetapp/provision', requestOptions)
      .then((response) => console.log(response))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled])

  const refreshLogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('application') && typeof clientprincipal?.tenantId === 'string' && subscription?.isEnabled){
      setIsLogLoading(true);
      const requestOptions = {
          method: 'GET',
          headers: {  'Content-Type': 'application/json',
                      'tenantId': clientprincipal.tenantId
          },
      };
      fetch('/api/application/log', requestOptions)
      .then((response) => response.json())
      .then((result) => setLogItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLogLoading(false);
      })
    } else {
      setItems([])
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, subscription?.isEnabled]);
  
  // fetch items from a backend API
  React.useEffect(() => {
    refreshItems();
  }, [clientprincipal, refreshItems]); // , setItems

  // fetch WinGetApp items from a backend API
  React.useEffect(() => {
    refreshWinGetAppItems();
  }, [clientprincipal, refreshWinGetAppItems]); // , setItems

  // fetch log items from a backend API
  React.useEffect(() => {
      refreshLogItems();
  }, [clientprincipal, refreshLogItems]); // , setItems

  // Init subscription object
  React.useEffect(() => {
    refreshSubscription();
  },[clientprincipal, refreshSubscription]);
  
  React.useEffect(() => {
    setServiceOffer(
      serviceOffers.find((element: IServiceOffer) => {
        return element.name === "application";
      }))
  },[serviceOffers, setServiceOffer]);

  const value = React.useMemo(() => ({serviceOffer, subscription, items, winGetAppItems, isLoading, logItems, isLogLoading, isWinGetAppItemsLoading, refreshItems, refreshWinGetAppItems, refreshSubscription, patchSubscription, refreshLogItems, setApplication, synchronizeApplication, provisionWinGetApp}), [serviceOffer, subscription, items, winGetAppItems, isLoading, logItems, isLogLoading, isWinGetAppItemsLoading, refreshItems, refreshWinGetAppItems, refreshSubscription, patchSubscription, refreshLogItems, setApplication, synchronizeApplication, provisionWinGetApp]) // , refreshItems
  return <ApplicationContext.Provider value={value} {...props} />
}
