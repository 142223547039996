import * as React from "react";
import { ServiceCard } from "./ServiceCard";

export const Settings = () => {
  return (
    <div>
      <ServiceCard />
    </div>
  )
};

export default Settings;

/*

import * as React from "react";
import {
  Button,
  makeStyles,
  shorthands,
  tokens,
  //Body1,
  //LargeTitle,
  //Subtitle1,
  //Title3,
} from "@fluentui/react-components";
import { 
  //Dismiss24Regular,
  //ArrowSync24Regular,
  //Open24Regular,
  //PresenceAvailable24Regular,
  //PresenceBlocked24Regular,
  Dismiss24Regular,
  //PresenceUnknown24Regular,
  Edit24Regular,
  Save24Regular
} from "@fluentui/react-icons";

// import { useRadiusContext } from '../../Context/RadiusContext'
//import type {
//  SelectTabData,
//  SelectTabEvent,
//  TabValue,
//} from "@fluentui/react-components";
import {
  Switch,
  SwitchProps,
} from "@fluentui/react-components";
import { Field, Input, Textarea } from "@fluentui/react-components";
import type { TextareaProps, InputProps } from "@fluentui/react-components";
import { DefaultIAztaSubscriptionItemValue, useAztaContext } from "../../Context/AztaContext";
import type { IAztaSubscriptionItem, IAztaServiceInformationObject, IAztaTechnicalContactPersonInformationObject } from "../../Context/AztaContext";
import { Spinner } from "@fluentui/react-components";
import type { SpinnerProps } from "@fluentui/react-components";

export const DefaultSpinner = (props: Partial<SpinnerProps>) => <Spinner {...props} />;

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

export const Settings = () => {
  const {subscription, validationState, validateSubscription, updateSubscription, refreshSubscription} = useAztaContext();
  const [subscriptionSettings, setSubscriptionSettings] = React.useState<IAztaSubscriptionItem>(subscription || DefaultIAztaSubscriptionItemValue);
  const styles = useStyles();
  //const [formChanged , setFormChanged] = React.useState<boolean>(false);
  
  const SubscriptionSettings = () => {
    // Edit
    const [isEdit , setIsEdit] = React.useState<boolean>(false);

    // Subscription isEnabled
    const SubscriptionEnabled = React.useCallback((props: SwitchProps) => (
      <Switch 
        label={subscriptionSettings.isEnabled ? ("Enabled") : ("Disabled")}
        checked={subscriptionSettings.isEnabled}
        onChange={() => setSubscriptionSettings({
          ...subscriptionSettings,
          isEnabled: !(subscriptionSettings.isEnabled)
        })}
        {...props}
      />
    ),[subscriptionSettings]);

    // Company Id
    const [idValue, setIdValue] = React.useState<string>(subscriptionSettings?.formData?.zeroTouchCompanyId || '');
    const [idValid, setIdValid] = React.useState<boolean>(((subscriptionSettings?.formData?.zeroTouchCompanyId || '').match("^[0-9]{10}$")) ? (true) : (false))
    const idTextLimit = 10
    const onIdChange: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= idTextLimit) {
        setIdValue(data.value);
      }

      // Validation rule
      if (data.value.match("^[0-9]{10}$")) { // (refZerotouchCompanyId.current.match('^\d{0,10}$')
        setIdValid(true);
      } else {
        setIdValid(false);
      }
    }
    
    // Company Name
    const [nameValue, setNameValue] = React.useState<string>(subscription?.formData?.zeroTouchCompanyName || '');
    const [nameValid, setNameValid] = React.useState<boolean>(((subscription?.formData?.zeroTouchCompanyName || '').match("^.{2,50}$"))? (true) : (false));
    const nameTextLimit = 50

    const onNameChange: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= nameTextLimit) {
        setNameValue(data.value);
      }

      // Validation rule
      if (data.value.match("^.{2,50}$")) {
        setNameValid(true);
      } else {
        setNameValid(false);
      }
    };

    const onClickCancelEditButton = () => {
      setIsEdit(false)
    };

    const EditButton = React.useCallback(() => {
      return (
        (isEdit) ? (
          <Button icon={<Dismiss24Regular />} shape="square" appearance="subtle" onClick={onClickCancelEditButton}>Cancel</Button>
        ) : (
          <Button icon={<Edit24Regular />} shape="square" appearance="subtle" onClick={() => setIsEdit(true)}>Edit</Button>
        )
      )
    },[validationState, isEdit])

    const textLimit = 50
    const [serviceInformationEmail, setServiceInformationEmail] = React.useState<string>(subscription?.formData?.serviceInformation?.email || '');
    const [technicalContactPersonInformationEmail, setTechnicalContactPersonInformationEmail] = React.useState<string>(subscription?.formData?.technicalContactPersonInformation?.email || '');
    const [technicalContactPersonInformationFirstName, setTechnicalContactPersonInformationFirstName] = React.useState<string>(subscription?.formData?.technicalContactPersonInformation?.firstName || '');
    const [technicalContactPersonInformationLastName, setTechnicalContactPersonInformationLastName] = React.useState<string>(subscription?.formData?.technicalContactPersonInformation?.lastName || '');
    const [technicalContactPersonInformationPhone, setTechnicalContactPersonInformationPhone] = React.useState<string>(subscription?.formData?.technicalContactPersonInformation?.phone || '');
 
    const onClickSaveButton = React.useCallback(() => {
      updateSubscription(
        {
          //tenantId: clientprincipal.tenantId,
          isEnabled: subscriptionSettings.isEnabled,
          formData: {
            zeroTouchCompanyId: idValue,
            zeroTouchCompanyName: nameValue,
            technicalContactPersonInformation: {
              email: technicalContactPersonInformationEmail,
              firstName: technicalContactPersonInformationFirstName,
              lastName: technicalContactPersonInformationLastName,
              phone: technicalContactPersonInformationPhone,
            },
            serviceInformation: {
              email: serviceInformationEmail,
            },
          }
        })
    }, [idValue, nameValue, subscriptionSettings, serviceInformationEmail,technicalContactPersonInformationEmail,technicalContactPersonInformationFirstName,technicalContactPersonInformationLastName,technicalContactPersonInformationPhone]);


    const SaveButton = React.useCallback(() => {
      const formChanged = (
        (subscriptionSettings.isEnabled != (subscription?.isEnabled || false)) ||
        (nameValue != (subscriptionSettings?.formData?.zeroTouchCompanyName || '')) ||
        (idValue != (subscriptionSettings?.formData?.zeroTouchCompanyId || '')) ||
        (serviceInformationEmail != (subscription?.formData?.technicalContactPersonInformation?.email || '')) ||
        (technicalContactPersonInformationEmail != (subscription?.formData?.technicalContactPersonInformation?.email || '')) ||
        (technicalContactPersonInformationFirstName != (subscription?.formData?.technicalContactPersonInformation?.firstName || '')) ||
        (technicalContactPersonInformationLastName !=(subscription?.formData?.technicalContactPersonInformation?.lastName || '')) ||
        (technicalContactPersonInformationPhone != (subscription?.formData?.technicalContactPersonInformation?.phone || ''))
      ) 
      return(
        (formChanged) ? (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton} >Save</Button>
        ) : (
          <Button icon={<Save24Regular />} shape="square" appearance="subtle" onClick={onClickSaveButton} disabled>Save</Button>
        )
        
      )
    },[idValue, nameValue, subscriptionSettings, serviceInformationEmail,technicalContactPersonInformationEmail,technicalContactPersonInformationFirstName,technicalContactPersonInformationLastName,technicalContactPersonInformationPhone]);

    const onChangeServiceInformationEmail: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setServiceInformationEmail(data.value);
      }
    };

    const onChangeTechnicalContactPersonInformationEmail: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setTechnicalContactPersonInformationEmail(data.value);
      }
    };
    
    const onChangeTechnicalContactPersonInformationFirstName: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setTechnicalContactPersonInformationFirstName(data.value);
      }
    };
  
    const onChangeTechnicalContactPersonInformationLastName: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setTechnicalContactPersonInformationLastName(data.value);
      }
    };
  
    const onChangeTechnicalContactPersonInformationPhone: InputProps["onChange"] = (ev, data) => {
      if (data.value.length <= textLimit) {
        setTechnicalContactPersonInformationPhone(data.value);
      }
    };
  
    return (
      (!validationState) ? (
          <DefaultSpinner />
      ):(
        <>
          <tr>
            <SubscriptionEnabled />
          </tr>
          <tr>
            <Field
            label="Company Id in Zero-touch portal (10 digits - example: 1234567890)"
            validationState={idValid ? "success" : "warning"}
            validationMessage={idValid ? "" : "Input must be a 10 digit company id."}
          >
              <Input value={idValue} onChange={onIdChange} disabled = {!isEdit} />
              {
                //<Textarea value={idValue} onChange={onIdChange} />
              }
            </Field>
          </tr>
          <tr>
            <Field 
              label="Company Name in Zero-touch portal (example: Contoso)"
              validationState={nameValid ? "success" : "warning"}
              validationMessage={nameValid ? "" : "Input is limited 50 characters."}
              >
              <Input value={nameValue} onChange={onNameChange}  disabled = {!isEdit}/>
            </Field>
          </tr>
          <Field 
            label="Service Information e-mail address"
            //validationState={valid ? "success" : "warning"}
            //validationMessage={valid ? "" : "Input is limited 50 characters."}
            >
            <Input value={serviceInformationEmail} onChange={onChangeServiceInformationEmail} />
          </Field>
          <Field 
            label="Technical contact Information e-mail address"
            //validationState={valid ? "success" : "warning"}
            //validationMessage={valid ? "" : "Input is limited 50 characters."}
            >
            <Input value={technicalContactPersonInformationEmail} onChange={onChangeTechnicalContactPersonInformationEmail} />
          </Field>
          <Field 
            label="Technical contact Information first name"
            //validationState={valid ? "success" : "warning"}
            //validationMessage={valid ? "" : "Input is limited 50 characters."}
            >
            <Input value={technicalContactPersonInformationFirstName} onChange={onChangeTechnicalContactPersonInformationFirstName} />
          </Field>
          <Field 
            label="Technical contact Information last name"
            //validationState={valid ? "success" : "warning"}
            //validationMessage={valid ? "" : "Input is limited 50 characters."}
            >
            <Input value={technicalContactPersonInformationLastName} onChange={onChangeTechnicalContactPersonInformationLastName} />
          </Field>
          <Field 
            label="Technical contact Information Phone"
            //validationState={valid ? "success" : "warning"}
            //validationMessage={valid ? "" : "Input is limited 50 characters."}
            >
            <Input value={technicalContactPersonInformationPhone} onChange={onChangeTechnicalContactPersonInformationPhone} />
          </Field>
          <EditButton />
          <SaveButton />
        </>
      )
    );
  }
  
  return (
    <div>
      <br />
      <table className={styles.propsTable}>
        <tbody>
          <tr>
            <td>AZTA Service</td>
          </tr>
          <tr>
            <SubscriptionSettings />
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    )
};

export default Settings;

*/