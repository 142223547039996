import * as React from "react";
import {
  Tab,
  TabList,
  SpinnerProps,
  Spinner,
} from "@fluentui/react-components";
import {
  //Apps24Filled,
  //Apps24Regular,
  MoneyCalculatorFilled,
  MoneyCalculatorRegular,
  DocumentOnePageMultiple24Filled,
  DocumentOnePageMultiple24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { default as Faq } from './Faq'
import { default as Invoices } from './Invoices'

const FaqIcons = bundleIcon(DocumentOnePageMultiple24Filled, DocumentOnePageMultiple24Regular);
const InvoiceIcons = bundleIcon(MoneyCalculatorFilled, MoneyCalculatorRegular);
export const DefaultSpinner = (props: Partial<SpinnerProps>) => <Spinner {...props} />;

export const TabsWithPanels = () => {
  const [selectedValue, setSelectedValue] = React.useState<TabValue>('default')
  
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const FaqPanel = React.memo(() => (
      <Faq />
  ));

  const InvoicesPanel = React.memo(() => (
      <Invoices />
  ));

  const ActiveTabs = React.useCallback(() => {
    return (
      <>
        <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
          <Tab id="FAQ" icon={<FaqIcons />} value="faq">
            FAQ
          </Tab>
          <Tab id="Invoices" icon={<InvoiceIcons />} value="invoices">
            Invoices
          </Tab>
        </TabList>
        <div className="App-contentgrid">
          {selectedValue === "default" && <InvoicesPanel />}
          {selectedValue === "faq" && <FaqPanel />}
          {selectedValue === "invoices" && <InvoicesPanel />}
        </div>
      </>
    )
  },[selectedValue, InvoicesPanel, FaqPanel])

  return (
    <ActiveTabs />
  );
};

export default TabsWithPanels;