import React from "react"
import {useClientprincipal} from '../Context/ClientprincipalContext'

export interface ISigninLogItem {
  tenantId: string;
  userprincipalName: string;
  userName: string;
  logTimestamp: string;
  roles: string
  amr: string;
  userId: string;
}

export interface IServiceStatusItem {
  // Service catalog from table: subscriptions
  tenantid: string;
  id: string; // serviceid
  name: string;
  isEnabled: boolean;
}

export interface IServiceManagementItem {
  // Service catalog from table: services and subscriptions
  id: string;
  description: string;
  displayname: string;
  name: string;
  isEnabled: boolean;
  currencycode: string;
  retailprice: string;
  unittype: string;
}

export type ServiceContextType = {
  items: IServiceManagementItem[];
  isLoading: boolean;
  signinlogItems: ISigninLogItem[];
  isLoadingSigninlogs: boolean;
  refreshItems: () => void;
  updateService: (props: Partial<IServiceStatusItem>) => void;
  getSigninlogItems: () => void;
};

const ServiceDefaultValue = {
  items: [],
  isLoading: false,
  signinlogItems: [],
  isLoadingSigninlogs: false,
  refreshItems: () => {},
  updateService: (props: Partial<IServiceStatusItem>) => {},
  getSigninlogItems: () => {},
};

export interface IProviderProps {
children?: any;
}

const ServiceContext = React.createContext<ServiceContextType>(ServiceDefaultValue)

export const useServiceContext = () => {
  const context = React.useContext(ServiceContext);
  if (!context) {
    throw new Error(
      `useServiceContext must be used within a ServiceProvider`
    )
  }
  return context;
}

export const ServiceProvider = (props: IProviderProps) => {
  const [items, setItems] = React.useState<IServiceManagementItem[]>([]);
  const [signinlogItems, setSigninlogItems] = React.useState<ISigninLogItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingSigninlogs, setIsLoadingSigninlogs] = React.useState<boolean>(false)
  
  const {clientprincipal} = useClientprincipal();
  
  const refreshItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') && clientprincipal.tenantId){
      setIsLoading(true);
      
      // Get services 
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId
                  },
      };
      fetch('/api/services', requestOptions)
      .then((response) => response.json())
      .then((result) => setItems(result))
      .catch((error) => console.log("An error occured"))
      .finally(() => {
          setIsLoading(false);
      })
    } else {
      setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId]);

  const updateService = React.useCallback((props: Partial<IServiceStatusItem>) => {
    if( clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') && clientprincipal.tenantId && clientprincipal.userName && clientprincipal.userId){
      setIsLoading(true);
      
      // Update Service
      console.log('Updating service status')
      const requestOptions = {
        method: 'MERGE',
        headers: {  'Content-Type': 'application/json', 
                    'tenantId': clientprincipal.tenantId,
                    'userName': clientprincipal.userName,
                    'userId': clientprincipal.userId
                  },
        body: JSON.stringify({
          id: props['id'],
          tenantid: clientprincipal.tenantId,
          isEnabled: props['isEnabled'],
          name: props['name']
        }),
      };
      fetch('/api/services', requestOptions)
      .then((response) => (response.status === 204) ? (
        refreshItems()
      ) : (
        console.log('Failed to set service status.') 
      ))
      .catch((error) => console.log("An error occured" + error))
      .finally(() => {
        setIsLoading(false);
      })
    }
  },[clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal.tenantId, clientprincipal.userName, clientprincipal.userId, refreshItems])

  const getSigninlogItems = React.useCallback(() => {
    if(clientprincipal?.isAuthenticated && clientprincipal?.roles?.includes('serviceadministrator') && clientprincipal.tenantId){
      setIsLoadingSigninlogs(true);
      
      // Get services 
      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json',
                    'tenantId': clientprincipal.tenantId
                  },
      };
      fetch('/api/services/sign-in/logs', requestOptions)
      .then((response) => response.json())
      .then((result) => {setSigninlogItems(result)})
      .catch((error) => console.log("An error occured"))
      .finally(() => {
        setIsLoadingSigninlogs(false);
      })
    } else {
      //setItems([])
    }
  }, [clientprincipal?.isAuthenticated, clientprincipal?.roles, clientprincipal?.tenantId]);


  // Init service items array
  React.useEffect(() => {
    refreshItems();
  },[clientprincipal, refreshItems]);

  // Init service items array
  React.useEffect(() => {
    getSigninlogItems();
  },[clientprincipal, getSigninlogItems]);
  
  const value = React.useMemo(() => ({items, isLoading, signinlogItems, isLoadingSigninlogs, refreshItems, updateService, getSigninlogItems}), [items, isLoading, signinlogItems, isLoadingSigninlogs, refreshItems, updateService, getSigninlogItems])
  return <ServiceContext.Provider value={value} {...props} />
}
